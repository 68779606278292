import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const getSurnameResolver = (t) => {
  const specialCharacters = /^[^!@#$^&%*;()<>]+$/
  const specialCharacterMessage = t('one-search.form.symbols.error', 'Cannot contain symbols.')
  const validLengthMessage = t('one-search.form.maxchar.error', 'Cannot be more than 128 characters.')
  return yupResolver(
    Yup.object().shape({
      yourLastName: Yup.string()
        .max(128, validLengthMessage)
        .trim()
        .matches(specialCharacters, {
          message: specialCharacterMessage,
          excludeEmptyString: true,
        })
        .test('yourLastName', '', (lastName) => {
          if (lastName === '') return false
          return true
        }),
    })
  )
}

export default getSurnameResolver
