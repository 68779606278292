/* eslint-disable import/prefer-default-export */
export function getFacebookId() {
  const hostname = window.location.hostname
  const env = hostname.match(/[^.]*/i)[0]
  switch (env) {
    case 'integration':
      return 1590341081292597
    case 'www': // prod
      return 1261365907621824
    default:
      // beta
      return 1590340951292610
  }
}

export function hasQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.has(param)
}
