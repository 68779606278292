import React from 'react'
import { useQueryParams } from '@fs/zion-router'
import SurnameResults from './Results'
import SurnameSearchPage from './Search/SurnameSearchPage'
import { useSurname } from '../../ResultsPage/hooks'

const SurnameResultsPrintView = React.lazy(() => import('./Results/ResultsPrintView'))

const SurnameExperienceCore = () => {
  const { query } = useQueryParams()
  let { surname, print } = query
  surname = surname?.trim() || ''
  print = print?.trim() || ''
  useSurname(surname)

  if (surname && print) {
    return <SurnameResultsPrintView />
  }
  if (surname) {
    return <SurnameResults />
  }
  return <SurnameSearchPage />
}

export default SurnameExperienceCore
