import React from 'react'
import PropTypes from 'prop-types'
import LocationImage from '@fs/zion-location-image'
import backUpImage from './invalid-icon-medium.svg'

export default function Country({ countryCode, countryColor = 'black', height, name, width }) {
  return (
    <LocationImage
      isoCode={countryCode}
      color={countryColor}
      height={height}
      width={width}
      onErrorImage={backUpImage}
      title={name}
    />
  )
}

Country.propTypes = {
  countryCode: PropTypes.string.isRequired,
  countryColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}
