import React from 'react'
import { useTranslation } from 'react-i18next'
import { Observable } from '@fs/zion-frontend-friends'
import { Button, colors, DataPreview, Ghost, LayoutBand, Row, Separator, Skeleton, usePageWidth } from '@fs/zion-ui'
import TreePreviewSection from './TreePreviewSection'
import { TreeProfileCards, TreeProfileCardsSkeleton } from '../../../../ResultsPage/TreeProfileTab'
import ErrorsOrNoResults from '../../../../ResultsPage/AllTab/ErrorsOrNoResults'
import { formatName } from '../../../../ResultsPage/utils'

const TreeSection = ({ state, surname }) => {
  const [t] = useTranslation()
  const isMobile = usePageWidth()({ default: true, lg: false })
  const { nameDisplay: lastName, nameParam: lastNameParam } = formatName(surname)
  const loadingTree = state?.isLoading
  const errorTree = state?.isError
  const treeCount = state?.data?.count

  return (
    <LayoutBand maxWidth="lg" color={colors.gray02} marginX={isMobile ? 'xl' : 'sm'} data-testid="section-tree">
      <Separator size="xl" />
      <Observable rootMargin="300px">
        <DataPreview.Loading loading={loadingTree}>
          <Skeleton.H1 />
          <Skeleton.Paragraph size="sm" />
          <TreeProfileCardsSkeleton />
          <Separator size="md" />
        </DataPreview.Loading>
        <DataPreview.Ready loading={loadingTree} error={errorTree}>
          <TreePreviewSection
            heading={t(
              'surname.results.tree.header',
              'Discover the life stories of ancestors in the {lastName} family tree',
              {
                lastName,
              }
            )}
            total={treeCount}
            linkName="Tree:View All"
          >
            <Ghost heightEstimate={500} keepVisible>
              <TreeProfileCards data={state?.data?.results} isPreview previewRows={isMobile ? 3 : 1} />
            </Ghost>
            <Separator size="lg" />
            <Row alignX="center">
              <Button
                type="button"
                emphasis="medium"
                external
                to={`/search/discovery/results/?tab=preview&q.surname=${lastNameParam}`}
                linkName="TreeSection: SeeMoreAncestors"
                data-testid="search-results-search-button"
              >
                {t('surname.results.see-more-ancestors.button', 'See More Ancestors')}
              </Button>
            </Row>
            <Separator size={isMobile ? 'xl' : 'md'} />
          </TreePreviewSection>
        </DataPreview.Ready>
      </Observable>

      <ErrorsOrNoResults
        {...{
          loadingTree,
          errorTree,
          emptyTree: treeCount === 0,
        }}
      />
      <Separator size="xl" />
    </LayoutBand>
  )
}

export default TreeSection
