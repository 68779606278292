import React from 'react'
import { Button, colors, Row } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useUser } from '@fs/zion-user'
import { i18n } from '@fs/zion-locale'
import { baseUrl } from '@fs/zion-config'
import { NAV_IDS } from './StickyNavSurnameSection'
import SurnameSection from './SurnameSection'
import AuthProviderButtons from '../../AuthProviderButtons'

export default function AccessAccountSection({ linkNamePrefix = 'AccessAccount' }) {
  const { t } = useTranslation()
  const user = useUser()
  const showProviderLogin = !user?.userLoading && !user?.signedIn

  const heading = showProviderLogin
    ? t('surname.create-account-section.join-familysearch.heading', 'Access FamilySearch')
    : t('surname.create-account-section.what-next.heading', 'Not sure what to do next?')
  const subHeading = showProviderLogin
    ? t(
        'surname.create-account-section.join-familysearch.subheading',
        'FamilySearch helps you explore your heritage, discover ancestors, and connect with your family’s rich history.'
      )
    : t(
        'surname.create-account-section.what-next.subheading',
        'We can show you how to do things like make a family tree or search for an ancestor. Choose an activity that fits your interest.'
      )

  return (
    <SurnameSection
      name="create-account"
      navId={NAV_IDS.createAccount}
      layoutBandStyle={{ background: colors.green10, minWidth: 320 }}
      heading={heading}
      subHeading={subHeading}
      headingSize="H3"
      maxWidth="xs"
    >
      {showProviderLogin ? (
        <AuthProviderButtons linkNamePrefix={linkNamePrefix} />
      ) : (
        <Row alignX="center">
          <Button
            to={`${baseUrl}/${i18n.language}/gettingstarted/`}
            emphasis="high"
            linkName="AccessAccountSection: GetStarted"
            color="gray"
          >
            {t('surname.create-account-section.get-started.label', 'Get started')}
          </Button>
        </Row>
      )}
    </SurnameSection>
  )
}
