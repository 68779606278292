import React from 'react'
import { i18n } from '@fs/zion-locale'
import { colors, Button, Row, Separator, Expander, ExpanderHeader, ExpanderContent, Divider, H3 } from '@fs/zion-ui'
import { useTranslation, Trans } from 'react-i18next'
import { NAV_IDS } from './StickyNavSurnameSection'
import SurnameSection from './SurnameSection'

function FAQExpander({ question, answer }) {
  return (
    <>
      <Expander>
        <ExpanderHeader>
          <H3 size="H5">{question}</H3>
        </ExpanderHeader>
        <ExpanderContent>
          <Separator size="xs" />
          {answer}
          <Separator size="sm" />
        </ExpanderContent>
      </Expander>
      <Divider marginY="sm" />
    </>
  )
}

export default function FAQSurnamesSection() {
  const { t } = useTranslation()
  return (
    <SurnameSection
      name="surname-faqs"
      navId={NAV_IDS.faqs}
      heading={t('surname.faq-surname-section.faq-questions.header', 'Frequent surname questions')}
      layoutBandStyle={{ background: colors.gray00, minWidth: 320 }}
      maxWidth="xs"
    >
      <Divider marginY="sm" />
      <FAQExpander
        question={t(
          'surname.faq-surname-section.rarest-names.question',
          'What are the rarest surnames in Family Tree?'
        )}
        answer={t(
          'surname.faq-surname-section.rarest-names.answer',
          'There are many rare names! Among the rarest found in Family Tree are: Sorinas, Bawdwyne, Pershick, Domani, Billaranda, Rablat, Simsdotter, Philipeauxi, Nichterwitz, Steinkulen'
        )}
      />
      <FAQExpander
        question={t(
          'surname.faq-surname-section.how-far-can-you-trace.question',
          'How far can you trace your family tree?'
        )}
        answer={t(
          'surname.faq-surname-section.how-far-can-you-trace.answer',
          "The ability to trace one's family tree varies greatly depending on factors such as available records, historical events, and cultural practices. Typically, the farthest back an individual can trace a family tree is a few hundred years."
        )}
      />
      <FAQExpander
        question={t('surname.faq-surname-section.how-is-familysearch-free.question', 'How is FamilySearch free?')}
        answer={t(
          'surname.faq-surname-section.how-is-familysearch-free.answer',
          'The Church of Jesus Christ of Latter-day Saints provides FamilySearch free of charge to everyone, regardless of tradition, culture, or religious affiliation. FamilySearch resources help millions of people around the world discover their heritage and connect with family members.'
        )}
      />
      <FAQExpander
        question={t('surname.faq-surname-section.what-is-familysearch.question', 'What is FamilySearch?')}
        answer={t(
          'surname.faq-surname-section.what-is-familysearch.answer',
          'FamilySearch is the world’s largest free genealogy resource. Visitors can search in billions of historical records for their ancestors. It is also home to the largest community family tree in the world. An experienced team of researchers can also provide genealogy guidance in person or online.'
        )}
      />
      <FAQExpander
        question={t(
          'surname.faq-surname-section.where-info-is-from.question',
          'Where does this last name information come from?'
        )}
        answer={
          <Trans
            i18nKey="surname.faq-surname-section.where-info-is-from.answer"
            defaults="Surname information comes from <italics>The Dictionary of American Family Names</italics> by Patrick Hanks. Hanks is an internationally renowned lexicographer and names researcher affiliated with Brandeis University."
            components={{ italics: <em /> }}
          />
        }
      />
      <Separator size="lg" />
      {i18n.language === 'en' && (
        <Row alignX="center">
          <Button
            to={`https://www.familysearch.org/${i18n.language}/blog/tag/surnames`}
            linkName="FAQSurnamesSection: MoreAboutSurnames"
            emphasis="medium"
          >
            {t('surname.faq-surname-section.more-about-surnames.label', 'More about surnames')}
          </Button>
        </Row>
      )}
    </SurnameSection>
  )
}
