/* eslint-disable import/prefer-default-export */
import { useRecords, useSurname, useTree } from '../../../ResultsPage/hooks'

export const useSurnamePage = (surname) => {
  const recordsData = useRecords({
    search: { 'q.surname': surname, 'm.queryRequireDefault': 'on' },
    pagination: { page: 1, results: 1 },
  })
  const surnameData = useSurname(surname)
  const treeData = useTree({
    search: { 'q.surname': surname, 'm.queryRequireDefault': 'on' },
    pagination: { page: 1, results: 4 },
  })

  return { recordsData, surnameData, treeData }
}
