import React from 'react'
import { Link, useQueryParams } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { Separator, Row, WhiteTheme, H2 } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { formatNameDisplay, formatNameParam } from '../../../ResultsPage/utils'

const headingCss = css`
  margin-top: -3px;
  & p {
    max-width: 100%;
  }
`

const SimilarSurnames = ({ variants = [] }) => {
  const { query } = useQueryParams()
  const { surname } = query
  const nameDisplay = formatNameDisplay(surname)

  const [t] = useTranslation()
  let variantList = variants.length > 9 ? [...variants].slice(0, 9) : [...variants]
  variantList.unshift({ label: nameDisplay, name: nameDisplay })
  variantList = [...new Set(variantList)]
  const actionChips = [
    ...variantList.map((variant) => {
      return {
        label: variant.name,
        value: variant.name,
      }
    }),
  ]

  return (
    <>
      {variants.length > 0 && (
        <>
          <Row gutters="xxs" wrap>
            <WhiteTheme css={headingCss}>
              <H2 size="H6">{t('surname.results.similar-names.label', 'Similar surnames:    ')}</H2>
              <Separator size="sm" />

              <Row gutters="none" wrap>
                {actionChips.map((variant, i) => {
                  const key = (variant?.label ?? '') + i
                  return (
                    <div key={key}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        to={`?surname=${formatNameParam(variant?.value)}`}
                        emphasis="low"
                        data-testid="similar-surname-item"
                        linkName={`SurnameDefinition:SimilarSurnames:${variant?.value}`}
                      >
                        {variant?.label}
                      </Link>
                      {i < actionChips.length - 1 && <span>{',\u00A0'}</span>}
                    </div>
                  )
                })}
              </Row>
            </WhiteTheme>
          </Row>
          <Separator size="md" />
        </>
      )}
    </>
  )
}

export default SimilarSurnames
