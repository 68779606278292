import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Button, Grid, useContainerWidth } from '@fs/zion-ui'
import ZionForm, { useZionForm, FormTextField, FormPlaceSuggest, useWatch } from '@fs/zion-form'
import BirthYearCalculator from '@fs/zion-birth-year-calculator'
import { i18n } from '@fs/zion-locale'
import { Link } from '@fs/zion-router'
import { trackLink } from '@fs/zion-analytics'
import { getResolver } from './utils'
import { formatNameDisplay, formatNameParam } from '../../../../ResultsPage/utils'

const useFormSubmit = (updateSearchValues, getValues) => {
  return (formData) => {
    const zionFormValues = getValues()
    formData.relevantPlace = zionFormValues?.suggestedPlace?.primaryText
    delete formData.suggestedPlace
    const queryMap = {
      givenName: 'q.givenName',
      surname: 'q.surname',
      relevantPlace: 'q.anyPlace',
      relevantYear: 'q.birthLikeDate.from',
    }
    // removing all undefined key/value pairs so that query param functions properly
    const searchValues = Object.fromEntries(
      Object.entries(formData)
        .filter(([, value]) => value)
        .map(([key, value]) => [queryMap[key], value])
    )
    if (searchValues['q.surname']) {
      searchValues['q.surname'] = formatNameParam(searchValues['q.surname'])
    }
    if (searchValues['q.givenName']) {
      searchValues['q.givenName'] = formatNameParam(searchValues['q.givenName'])
    }
    updateSearchValues({ searchValues })

    trackLink({ name: 'Form:Search:Submit' })
  }
}

export default function SearchForm({ searchValues = {}, updateSearchValues = () => {}, additionalActions }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()

  const {
    'q.givenName': givenName,
    'q.surname': surname,
    'q.anyPlace': relevantPlace,
    'q.birthLikeDate.from': birthLikeDate,
  } = searchValues

  const methods = useZionForm({
    mode: 'all',
    resolver: getResolver(t),
    defaultValues: { suggestedPlace: null },
    reValidateMode: 'onChange',
  })
  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    setValue,
    trigger,
  } = methods
  const handleFormSubmit = useFormSubmit(updateSearchValues, getValues, reset)
  const setCalculatedYear = (calculatedYear) => {
    // The trigger function call is to make sure that the year validation happens after BirthYearCalculator updates the year field.
    // If you click open the BirthYearCalculator first thing right after a page reload, the validation for < 1700 > currentYear will not work.
    // If you click in the relevantYear field first after a page reload and then open the BirthYearCalculator, that validation will work.
    setValue('relevantYear', calculatedYear)
    trigger('relevantYear')
  }

  useEffect(() => {
    const formValues = {}

    if (givenName) formValues.givenName = formatNameDisplay(givenName)
    if (surname) formValues.surname = formatNameDisplay(surname)
    if (relevantPlace) formValues.suggestedPlace = { key: '', primaryText: relevantPlace }
    if (birthLikeDate) formValues.relevantYear = birthLikeDate

    // Whenever the props change, we need to update the form fields
    reset(formValues)

    // Manually trigger validation after resetting the form
    trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthLikeDate, relevantPlace, givenName, surname])

  const relevantYear = useWatch({ name: 'relevantYear', control })
  const relevantYearRef = useRef()

  // eslint-disable-next-line consistent-return
  const yearHelpMessage = (yearInput) => {
    if (yearInput >= 1911) {
      return (
        <>
          {t(
            'one-search.form.living-people-privacy.blurb',
            'Information about living people is limited for privacy reasons.'
          )}{' '}
          <Link to="/terms" linkName="Birth Year: Learn More Privacy" external>
            {t('common-ui:learn-more.action', 'Learn More')}
          </Link>
        </>
      )
    }
  }

  const firstNameProps = {
    name: 'givenName',
    label: t('surname.results.discovery-search.form.first-names.label', "Ancestor's Names"),
  }
  const lastNameProps = {
    name: 'surname',
    label: t('one-search.form.last-names.label', 'Last Names'),
    helpMessage: t('one-search.form.required.field', 'Required'),
  }
  const { language } = i18n
  const isCJK = ['ja', 'ko', 'zh', 'zh-hans'].includes(language)

  return (
    <ZionForm methods={methods} onSubmit={handleFormSubmit}>
      <Grid guttersY="none">
        <FormTextField
          {...(isCJK ? lastNameProps : firstNameProps)}
          columns={atWidth({ md: 3, lg: 6, xl: 3 })}
          data-testid="name-field1"
        />
        <FormTextField
          {...(isCJK ? firstNameProps : lastNameProps)}
          columns={atWidth({ md: 3, lg: 6, xl: 3 })}
          data-testid="name-field2"
        />
        <FormPlaceSuggest
          label={t('one-search.form.relevant-place.label', 'Place Lived')}
          name="suggestedPlace"
          placeholder={t('one-search.form.relevant-place.placeholder', 'City, County, State, Country')}
          handleInput
          columns={atWidth({ md: 3, lg: 6, xl: 3 })}
          data-testid="place-field"
        />
        <Row gutters="xxs" columns={atWidth({ md: 3, lg: 6, xl: 3 })}>
          <FormTextField
            ref={relevantYearRef}
            label={t('one-search.form.estimated-birth-year.label', 'Estimated Birth Year')}
            name="relevantYear"
            type="number"
            helpMessage={yearHelpMessage(relevantYear)}
            data-testid="year-field"
          />
          <BirthYearCalculator overlayAnchorRef={relevantYearRef} setCalculatedYearField={setCalculatedYear} />
        </Row>

        <Row gutters="xxs" columns={atWidth({ md: 3, lg: 12, xl: 6 })} alignX={atWidth({ md: 'center', lg: 'start' })}>
          <Button
            type="submit"
            emphasis="high"
            disabled={!isValid}
            linkName="SearchForm: SearchNow"
            data-testid="refine-search-form-search-button"
            color="gray"
          >
            {t('surname.results.discovery-search.search.button', 'Search')}
          </Button>
          {additionalActions}
        </Row>
      </Grid>
    </ZionForm>
  )
}
