import React from 'react'
import { LayoutBand, FlowGrid, useContainerWidth, Separator, usePageWidth, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import CollectionCard from './CollectionCard'
import censusCollection from './assets/census-record.jpg'
import passengerListsCollection from './assets/passenger-list.jpg'
import draftCardsCollection from './assets/draft-card.jpg'
import { formatName } from '../../../../ResultsPage/utils'

const CollectionsSection = ({ surname }) => {
  const atWidth = useContainerWidth()
  const isMobile = usePageWidth()({ default: true, lg: false })
  const [t] = useTranslation()
  const { nameDisplay, nameParam } = formatName(surname)

  return (
    <LayoutBand maxWidth="lg" data-testid="section-collection">
      <Separator size="xxl" />
      <H2 centered size="H3">
        {t('surname.results.collections.header', 'What {surname} family records will you find?', {
          surname: nameDisplay,
        })}
      </H2>
      <Separator size="lg" />
      <FlowGrid columnCount={atWidth({ default: 1, md: 2, lg: 3 })}>
        <CollectionCard
          imageSrc={censusCollection}
          imageTitle={t('surname.results.collections.census-records', 'Census Records')}
          bodyText={t(
            'surname.results.collections.census-records-body',
            'Census can provide valuable information about your ancestors, such as their occupation, education, household, and more.',
            { surname: nameDisplay }
          )}
          cardLink={t('surname.results.collections.censusAction', 'search census collection')}
          cardColor="#ffffff"
          linkUrl={`/search/all-collections/results/?q.surname=${nameParam}&offset=0&searchType=records&c.collectionId=on&m.facetNestCollectionInCategory=on&c.collectionType=on&f.collectionType=0`}
          dataTestid="surname-census-collection"
        />
        <CollectionCard
          imageSrc={passengerListsCollection}
          imageTitle={t('surname.results.collections.passenger-lists', 'Passenger Lists')}
          bodyText={t(
            'surname.results.collections.passenger-lists-body',
            "Immigration records can reveal passenger lists with vital journey details like the ship's name and ports of departure and arrival."
          )}
          cardLink={t('surname.results.collections.passengerListsAction', 'search passenger lists collection')}
          cardColor="#ffffff"
          linkUrl={`/search/all-collections/results/?q.surname=${nameParam}&offset=0&searchType=records&c.collectionId=on&m.facetNestCollectionInCategory=on&c.collectionType=on&f.collectionType=4`}
          dataTestid="surname-passenger-list-collection"
        />
        <CollectionCard
          imageSrc={draftCardsCollection}
          imageTitle={t('surname.results.collections.draft-cards', 'Draft Cards')}
          bodyText={t(
            'surname.results.collections.draft-cards-body',
            'Military records offer valuable information on the service of your veteran ancestors, such as the time and place of their deployment.'
          )}
          cardLink={t('surname.results.collections.draftCardsAction', 'search draft cards collection')}
          cardColor="#ffffff"
          linkUrl={`/search/all-collections/results/?q.surname=${nameParam}&searchType=records&c.collectionId=on&m.facetNestCollectionInCategory=on&c.collectionType=on&f.collectionType=5`}
          dataTestid="surname-draft-cards-collection"
        />
      </FlowGrid>
      <Separator size={isMobile ? 'xxl' : 'xxl'} />
    </LayoutBand>
  )
}

export default CollectionsSection
