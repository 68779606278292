import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const getSharedSurnamesResolver = (t) => {
  const specialCharacters = /^[^!@#$^&%*;()<>]+$/
  const specialCharacterMessage = t('one-search.form.symbols.error', 'Cannot contain symbols.')
  const validLengthMessage = t('one-search.form.maxchar.error', 'Cannot be more than 128 characters.')
  return yupResolver(
    Yup.object().shape({
      yourLastName: Yup.string()
        .max(128, validLengthMessage)
        .trim()
        .required(t('one-search.form.your-last-name.error', 'Your last name is required.'))
        .matches(specialCharacters, {
          message: specialCharacterMessage,
          excludeEmptyString: true,
        }),
      friendsLastName: Yup.string()
        .max(128, validLengthMessage)
        .trim()
        .required(t('one-search.form.friends-last-name.error', 'Your friends last name is required.'))
        .matches(specialCharacters, {
          message: specialCharacterMessage,
          excludeEmptyString: true,
        }),
    })
  )
}

export default getSharedSurnamesResolver
