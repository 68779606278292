import React from 'react'
import { useTranslation } from 'react-i18next'
import { Ellipsis, DataBlock, Paragraph, Skeleton, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { getAllPersonFacts, getFocusPerson, getFactByType } from '@fs/zion-gedcomx'
import { getFactPlaceOriginal } from '@fs/zion-gedcomx/dist/cjs/gedx/factService'

const titleColor = css`
  color: ${colors.transparent.gray70};
`

const containerCss = css`
  align-items: center;
  display: flex;
  height: 64px;
  & > div {
    width: 100%;
  }
`
const skeletonContainerCss = css`
  ${containerCss}
  flex-direction: column;
  align-items: flex-start;
`

export const EventSkeleton = () => {
  return (
    <div css={skeletonContainerCss} data-testid="TreeProfileTab-skeleton">
      <div css={{ width: 60, marginBottom: -22, marginTop: 2 }}>
        <Skeleton.H5 />
      </div>
      <div css={{ width: 200 }}>
        <Skeleton.H5 />
      </div>
    </div>
  )
}

export default function Event({ person }) {
  const [t] = useTranslation()

  let place = null

  const focusedPerson = getFocusPerson(person?.gedcomx)
  const facts = getAllPersonFacts(person?.gedcomx, focusedPerson)
  const birth = getFactByType(facts, 'http://gedcomx.org/Birth')
  const christening = getFactByType(facts, 'http://gedcomx.org/Christening')
  const death = getFactByType(facts, 'http://gedcomx.org/Death')
  const burial = getFactByType(facts, 'http://gedcomx.org/Burial')

  if (birth) place = { type: t('one-search.birth.label', 'Birth'), address: getFactPlaceOriginal(birth) }
  else if (christening)
    place = { type: t('one-search.christening.label', 'Christening'), address: getFactPlaceOriginal(christening) }
  else if (death) place = { type: t('one-search.death.label', 'Death'), address: getFactPlaceOriginal(death) }
  else if (burial) place = { type: t('one-search.burial.label', 'Burial'), address: getFactPlaceOriginal(burial) }

  return (
    <div css={containerCss}>
      {place?.address ? (
        <DataBlock
          as="h4"
          data-testid="card-event"
          heading={<span css={titleColor}>{place.type}</span>}
          data={<Ellipsis>{place?.address}</Ellipsis>}
        />
      ) : (
        <Paragraph data-testid="card-event-none" size="xs">
          {t('one-search.profile-card.no-place.text', 'No place information')}
        </Paragraph>
      )}
    </div>
  )
}
