import React from 'react'
import { useTranslation } from 'react-i18next'
import { PersonBlock, Ellipsis, DataBlock, Paragraph, Skeleton, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'

const titleColor = css`
  color: ${colors.transparent.gray70};
`

const relatedPersonCss = css`
  margin-top: 4px;
`
const containerCss = css`
  align-items: left;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    inset-inline-start: 0;
    height: 100px;
    width: 100%;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(rgba(255, 255, 255, 0) 68%, ${colors.gray02} 100%);
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 68%, ${colors.gray02} 100%);
    background: linear-gradient(rgba(255, 255, 255, 0) 68%, ${colors.gray02} 100%);
  }
`

const skeletonContainerCss = css`
  ${containerCss}
  flex-direction: column;
  align-items: flex-start;
`

export const RelationshipSkeleton = () => {
  return (
    <div css={skeletonContainerCss}>
      <div css={{ marginBottom: -16, width: 60 }}>
        <Skeleton.H5 />
      </div>
      <div css={{ width: '100%' }}>
        <Skeleton.ListItem dense hasIcon />
      </div>
    </div>
  )
}

const RelatedPerson = ({ relative }) => {
  const sex = relative?.gender?.type?.split?.('/').at?.(-1)?.toLowerCase?.() ?? 'unknown'
  return (
    <div css={relatedPersonCss}>
      <PersonBlock
        avatarSize="smaller"
        name={
          <Paragraph size="sm">
            <Ellipsis>{relative?.display?.name}</Ellipsis>
          </Paragraph>
        }
        size="sm"
        avatarProps={{
          src: relative?.image?.contentUrl,
          sex,
          size: 'xs',
        }}
      />
    </div>
  )
}

const Spouse = ({ spouseObj }) => {
  const [t] = useTranslation()
  const relative = spouseObj?.[0]
  return relative ? (
    <DataBlock
      as="h4"
      heading={<span css={titleColor}>{t('one-search.spouse.label', 'Spouse')}</span>}
      data={<RelatedPerson relative={relative} />}
      data-testid="card-relationship-spouse"
    />
  ) : null
}

const Parent = ({ parentObj }) => {
  const [t] = useTranslation()
  return parentObj?.length ? (
    <DataBlock
      as="h4"
      heading={
        <span css={titleColor}>
          {parentObj.length > 1 ? t('one-search.parents.label', 'Parents') : t('one-search.parent.label', 'Parent')}
        </span>
      }
      data={
        <>
          {parentObj.map((p) => (
            <RelatedPerson key={p.id} relative={p} />
          ))}
        </>
      }
      data-testid="card-relationship-parent"
    />
  ) : null
}

const Children = ({ childrenObj }) => {
  const [t] = useTranslation()
  return childrenObj?.length ? (
    <DataBlock
      as="h4"
      heading={
        <span css={titleColor}>
          {childrenObj.length > 1 ? t('one-search.children.label', 'Children') : t('one-search.child.label', 'Child')}
        </span>
      }
      data={
        <>
          {childrenObj.map((c) => (
            <RelatedPerson key={c.id} relative={c} />
          ))}
        </>
      }
      data-testid="card-relationship-children"
    />
  ) : null
}

export default function Relationship({ person, hasMemories = false }) {
  const [t] = useTranslation()
  const hasParent = person?.parent?.length
  const hasSpouse = person?.spouse?.length
  const relations = person?.parent?.length || person?.spouse?.length || person?.children?.length
  return (
    <div css={containerCss}>
      <Spouse spouseObj={person.spouse} />
      {(!hasMemories || !hasSpouse) && <Parent parentObj={person.parent} />}
      {!hasMemories && (!hasParent || !hasSpouse) && <Children childrenObj={person.children} />}
      {!relations && (
        <Paragraph data-testid="card-relationship-none" size="xs">
          {t('one-search.profile-card.no-relationships.text', 'No relationships')}
        </Paragraph>
      )}
    </div>
  )
}
