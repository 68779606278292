import { stringify } from 'qs'

export const FEATURE_TAG_ENABLED = true
export const isEqualObjects = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)

// settings for creating the query string from an object
const stringifyOptions = {
  encodeValuesOnly: true,
  arrayFormat: 'repeat',
  sort: (a, b) => {
    return a.localeCompare(b)
  },
}

/**
 * Correctly format query string, handling filters, and stringifying the result
 * Copied from Zion record-details: https://github.com/fs-webdev/zion/blob/master/packages/record-search/src/search-results/resultsServices.js#L292-L308
 * @param queryObject
 * @returns {string}
 */
export function getQueryString(queryObject) {
  const queryCopy = { ...queryObject }
  const filters = queryCopy.filters || []
  let filterQuery = ''
  filters.forEach((param) => {
    const [key, value] = param.split('=')
    filterQuery = `${filterQuery}&${key}=${encodeURIComponent(value)}`
  })
  delete queryCopy.filters

  return `${stringify(queryCopy, stringifyOptions)}${filterQuery}`
}

/**
 * Get the header to save search in PAH
 * @param
 * @returns {{"X-FS-Feature-Tag": string}|{}}
 */
export function getOSOHeader() {
  return FEATURE_TAG_ENABLED ? { 'X-FS-Feature-Tag': 'pah.enabled' } : {}
}

export default null
