import { useTranslation } from 'react-i18next'
import { SocialBar } from '@fs/zion-social'
import { baseUrl } from '@fs/zion-config'
import React from 'react'
import { Row, useContainerWidth, colors, Cell } from '@fs/zion-ui'
import { i18n } from '@fs/zion-locale'
import { formatName } from '../../ResultsPage/utils'

const defaultAnalytics = {
  link_name: 'SurnameSearch:SocialBarShare',
  link_page: 'Surname SEO:Search',
  link_app: 'one-search',
}
const empty = {}

function getShareable({ surname, t }) {
  if (!surname)
    return {
      url: `${baseUrl}${window.location.pathname}`,
      subject: t('surname.app.og.title', 'Find your last name’s meaning and origin.'),
      message: t('surname.app.og.description', 'Begin learning more about yourself and your heritage.'),
    }

  const { nameDisplay, nameParam } = formatName(surname)
  return {
    // The shared content is specific to the language it was viewed in, currently not cross-compatible to other locales. So we hardcode it.
    url: `${window.location.origin}/${i18n.language}/surname?surname=${nameParam}`,
    subject: t(
      'displaySurname.results.og.title',
      '{surname} Name Meaning and {surname} Family History at FamilySearch',
      {
        surname: nameDisplay,
      }
    ),
  }
}

function getInfographicImage(surname) {
  if (!surname) return null
  const { nameDisplay } = formatName(surname)
  return `${window.location.origin}/service/home/fig/lang/${i18n.language}/surname/v5/${encodeURI(nameDisplay)}.png`
}

export default function SurnamePageSocialBar({ analytics = defaultAnalytics, customStyles = empty, surname }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const styles = {
    fontSize: 14,
    color: colors.green40,
    marginInlineStart: atWidth({ md: 16 }),
    fontWeight: 700,
    ...customStyles,
  }

  const shareable = getShareable({ surname, t })

  // We use the nameDisplay format because the service generates the surname in the image using the case provided.
  const infographicImage = getInfographicImage(surname)

  return (
    <Row gutters="xs" alignY="middle" wrap data-testid="social-share">
      <Cell style={styles}>{t('surname-app.social-share-bar.share.label', 'Share')}:</Cell>

      <SocialBar
        noShrinkX
        color="gray"
        useIconButtons
        downloadUrl={infographicImage}
        data-testid="social-platforms"
        platforms={['facebook', 'twitter', 'whatsApp', 'messenger', 'copyLink']}
        sharable={shareable}
        analytics={analytics}
      />
    </Row>
  )
}
