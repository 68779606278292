import React from 'react'
import { useContainerWidth, Skeleton, colors, Separator, Row, LayoutBand, FlowGrid, Grid, Cell } from '@fs/zion-ui'
import { css } from '@emotion/core'
import worldMap from './assets/WorldMap.webp'

const backgroundCss = css`
  background-color: ${colors.green60}; /* Set the background color to green */
  background-image: url(${worldMap});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const countryWrapper = css`
  background: ${colors.gray00};
`

const centerCss = css`
  text-align: center;
`

export default function SurnameLoading() {
  const atWidth = useContainerWidth()

  return (
    <>
      <div css={backgroundCss}>
        <Separator size={atWidth({ default: 'lg', sm: 'xl' })} />
        <Skeleton.H1 centered maxWidth={atWidth({ default: 300, sm: 544 })} />
        <Separator size={atWidth({ default: 'lg', sm: 'sm', md: 'xl' })} />
        <Grid columns={12}>
          <Cell columns={atWidth({ default: 12, md: 7 })}>
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
          </Cell>
          <Cell columns={atWidth({ default: 12, md: 5 })}>
            <Skeleton.Paragraph centered />
            <Skeleton.Paragraph centered />
          </Cell>
        </Grid>
        <Separator size={atWidth({ default: 'lg', sm: 'sm', md: 'xxl' })} />
        <Skeleton.Paragraph centered maxWidth={atWidth({ default: 300, sm: 544 })} />
        <Skeleton.Paragraph centered maxWidth={atWidth({ default: 300, sm: 544 })} />
        <Separator size={atWidth({ default: 'lg', sm: 'sm', md: 'xxl' })} />
      </div>
      <div css={countryWrapper}>
        <Separator size={atWidth({ default: 'lg', md: 'xl' })} />
        <Skeleton.H1 centered maxWidth={atWidth({ default: 280, sm: 340 })} />
        <Row alignX="center" gutters="xxl" guttersY="nano" wrap>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
        </Row>
      </div>

      <div css={countryWrapper}>
        <LayoutBand css={centerCss} size="md" maxWidth="xxs">
          <FlowGrid columnCount={atWidth({ default: 3 })}>
            <div>
              <Skeleton.H1 maxWidth={atWidth({ default: 65, sm: 110 })} />
            </div>
            <div>
              <Skeleton.H1 maxWidth={atWidth({ default: 65, sm: 110 })} />
            </div>
            <div>
              <Skeleton.H1 maxWidth={atWidth({ default: 65, sm: 110 })} />
            </div>
            <div>
              <Skeleton.H1 maxWidth={atWidth({ default: 65, sm: 110 })} />
            </div>
            <div>
              <Skeleton.H1 maxWidth={atWidth({ default: 65, sm: 95 })} />
            </div>
          </FlowGrid>
          <Separator size="xl" />
        </LayoutBand>
      </div>
    </>
  )
}
