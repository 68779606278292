import {
  getFocusPerson,
  getCollectionDetails,
  getFactDate,
  getFactPlace,
  getAllPersonFacts,
  getPersonBestName,
  getRelationshipType,
  getImageURL,
  getPersonDisplayData,
  getPersonSpouses,
  getPersonParents,
  getPersonChildren,
  getPersonLifespan,
} from '@fs/zion-gedcomx'

const CJK_LOWER_BOUND = '⺀'
function isCJKChar(testMe) {
  // the CJK_LOWER_BOUND (⺀) character seems to be the first asian character found in unicode... all others ideally have a higher unicode value.
  return testMe >= CJK_LOWER_BOUND
}

const formatNameDisplay = (nameString) => {
  if (!nameString) return ''
  const lastName = nameString?.trim?.()
  const names = lastName?.split(' ')

  return (
    names
      ?.filter((name) => Boolean(name))
      ?.map((name) => {
        return name[0].toUpperCase() + name.substring(1).toLowerCase()
      })
      ?.join(' ') || ''
  )
}

const formatNameParam = (nameString) => {
  const lastName = nameString?.trim?.()
  const firstChar = nameString?.charAt?.(0)
  if (!lastName || !firstChar) {
    return ''
  }
  if (isCJKChar(firstChar)) {
    return nameString
  }
  return nameString.toLowerCase()
}

const formatName = (surname) => {
  return { nameDisplay: formatNameDisplay(surname), nameParam: formatNameParam(surname) }
}

function lookupRelationshipMap(type) {
  const relationshipMap = {
    child: [
      'AdoptedSon',
      'AdoptedDaughter',
      'AdoptedChild',
      'FosterSon',
      'FosterDaughter',
      'FosterChild',
      'GuardianSon',
      'GuardianDaughter',
      'GuardianChild',
      'StepSon',
      'StepDaughter',
      'StepChild',
      'Son',
      'Daughter',
      'Child',
      'SonInLaw',
      'DaughterInLaw',
      'ChildInLaw',
    ],
    father: ['AdoptiveFather', 'FosterFather', 'GuardianFather', 'StepFather', 'Father', 'FatherInLaw'],
    mother: ['AdoptiveMother', 'FosterMother', 'GuardianMother', 'StepMother', 'Mother', 'MotherInLaw'],
    parent: ['AdoptiveParent', 'FosterParent', 'GuardianParent', 'StepParent', 'Parent', 'ParentInLaw'],
    spouse: [
      'ExHusband',
      'ExWife',
      'ExSpouse',
      'MaleFiance',
      'FemaleFiance',
      'Fiance',
      'DomesticHusband',
      'DomesticWife',
      'DomesticSpouse',
      'Husband',
      'Wife',
      'Spouse',
    ],
  }
  let found = null
  if (type) {
    const foundEntry = Object.entries(relationshipMap).find(([, rels]) => rels.includes(type))
    if (foundEntry) found = foundEntry[0]
  }
  return found
}

/**
 * Get the relationships listed in the record
 * @param {Object} gedcomx the record
 * @param {Object} focusPerson gedcomx person
 * @return {Object} The relationships
 */
function getRelationships(gedcomx, focusPerson) {
  const childNames = []
  const fatherNames = []
  const motherNames = []
  const otherNames = []
  const parentNames = []
  const spouseNames = []

  gedcomx.persons.forEach((person) => {
    const name = getPersonBestName(person)
    if (person.id !== focusPerson.id) {
      const relType = getRelationshipType(gedcomx, person, focusPerson)
      const key = relType ? lookupRelationshipMap(relType.labelKey) : null
      switch (key) {
        case 'child':
          childNames.push(name)
          break
        case 'father':
          fatherNames.push(name)
          break
        case 'mother':
          motherNames.push(name)
          break
        case 'parent':
          parentNames.push(name)
          break
        case 'spouse':
          spouseNames.push(name)
          break
        default:
          otherNames.push(name)
      }
    }
  })

  const relationships = {
    childNames: childNames.length ? childNames : undefined,
    fatherNames: fatherNames.length ? fatherNames : undefined,
    motherNames: motherNames.length ? motherNames : undefined,
    otherNames: otherNames.length ? otherNames : undefined,
    parentNames: parentNames.length ? parentNames : undefined,
    spouseNames: spouseNames.length ? spouseNames : undefined,
  }
  Object.keys(relationships).forEach((key) => (relationships[key] === undefined ? delete relationships[key] : {}))

  return relationships
}

function getFormattedFacts(record, person) {
  return getAllPersonFacts(record, person)
    .map((e) => {
      const date = getFactDate(e)
      const place = getFactPlace(e)
      if (date || place) {
        return {
          type: e.type,
          date: getFactDate(e),
          place: getFactPlace(e),
        }
      }
      return null
    })
    .filter((v) => Boolean(v))
}

const formatRecord = (record) => {
  const {
    content: { gedcomx },
    score,
  } = record
  const person = getFocusPerson(gedcomx)
  const imageUrl = getImageURL(gedcomx)
  let thumbnailUrl = null
  if (imageUrl && imageUrl.includes('familysearch.org/ark') && imageUrl.includes('/3:1:')) {
    const recordId = imageUrl.replace(/.*\/|\?.*$/g, '')
    thumbnailUrl = `https://www.familysearch.org/dz/v1/${recordId}/thumb_p200.jpg`
  }
  const url = gedcomx.links.self.href
  return {
    url,
    featuredPerson: {
      name: person.display.name,
      collectionName: getCollectionDetails(gedcomx)?.collectionName,
    },
    facts: getFormattedFacts(gedcomx, person),
    relationships: getRelationships(gedcomx, person),
    thumbnailUrl,
    originalRecord: record,
    score,
  }
}

const formatTreeResult = (result) => {
  const {
    content: { gedcomx, portrait, artifacts },
    score,
  } = result

  const person = getFocusPerson(gedcomx)
  const spouse = getPersonSpouses(gedcomx, person)?.map?.(({ person: p }) => p)
  const parent = getPersonParents(gedcomx, person)?.map?.(({ person: p }) => p)
  const children = getPersonChildren(gedcomx, person)?.map?.(({ person: p }) => p)
  const personDetails = getPersonDisplayData(person)
  const lifespan = getPersonLifespan(person)
  const genders = { F: 'female', M: 'male' }
  const gender = genders[personDetails?.sex] || 'unknown'

  return {
    ...personDetails,
    artifacts,
    children,
    gender,
    gedcomx,
    identifier: person?.id,
    lifespan,
    parent,
    portrait,
    score,
    spouse,
  }
}

const getNameParameters = (query) => {
  const plus = encodeURIComponent('+')
  let parametersFullTextName = ''
  const fullTextArray = [
    ['q.givenName', 'q.surname'],
    ['q.givenName.1', 'q.surname.1'],
    ['q.givenName.2', 'q.surname.2'],
    ['q.givenName.3', 'q.surname.3'],
    ['q.spouseGivenName', 'q.spouseSurname'],
    ['q.spouseGivenName.1', 'q.spouseSurname.1'],
    ['q.spouseGivenName.2', 'q.spouseSurname.2'],
    ['q.motherGivenName', 'q.motherSurname'],
    ['q.motherGivenName.1', 'q.motherSurname.1'],
    ['q.motherGivenName.2', 'q.motherSurname.2'],
    ['q.fatherGivenName', 'q.fatherSurname'],
    ['q.fatherGivenName.1', 'q.fatherSurname.1'],
    ['q.fatherGivenName.2', 'q.fatherSurname.2'],
    ['q.otherGivenName', 'q.otherSurname'],
    ['q.otherGivenName.1', 'q.otherSurname.1'],
    ['q.otherGivenName.2', 'q.otherSurname.2'],
  ]

  parametersFullTextName += `q.fullName=${plus}`
  fullTextArray.forEach((item) => {
    const space = item[0] in query && item[1] in query ? ' ' : ''
    if (item[0] in query || item[1] in query) {
      parametersFullTextName += `"`
      if (item[0] in query) {
        parametersFullTextName += `${query[item[0]]}${space}`
      }
      if (item[1] in query) {
        parametersFullTextName += `${query[item[1]]}`
      }
      parametersFullTextName += `" `
    }
  })
  return parametersFullTextName.trim()
}

const getPlaceParameters = (query) => {
  let parametersFullTextPlace = ''
  const placeArray = ['q.anyPlace', 'q.birthLikePlace', 'q.marriageLikePlace', 'q.residencePlace', 'q.deathLikePlace']
  for (let index = 0; index < placeArray.length; index++) {
    if (placeArray[index] in query) {
      parametersFullTextPlace += `&q.anyPlace=${query[placeArray[index]]}`
      break
    }
  }
  return parametersFullTextPlace
}

const getImageGroupNumberParameters = (query) => {
  let parametersFullTextGroupNumber = ''
  if ('q.filmNumber' in query) {
    parametersFullTextGroupNumber += `&q.groupName=${query['q.filmNumber']}`
  }
  return parametersFullTextGroupNumber
}

const formatFullTextParameters = (query) => {
  let parametersFullText = getNameParameters(query)
  parametersFullText += getPlaceParameters(query)
  parametersFullText += getImageGroupNumberParameters(query)
  return parametersFullText.trim()
}

export {
  formatNameParam,
  formatNameDisplay,
  formatName,
  formatRecord,
  formatTreeResult,
  formatFullTextParameters,
  lookupRelationshipMap,
  getRelationships,
}
