import React from 'react'
import { useTranslation } from 'react-i18next'
import { NoRecords } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function RecordsError({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoRecords,
    heading: t('one-search.records-error.heading', 'Historical records data is unavailable.'),
    subheading: t('one-search.data.error', 'You may need to wait a few minutes and try again.'),
    testid: 'records-error',
  }

  return small ? <SmallNoResults {...data} /> : <BigNoResults {...data} />
}
