import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@fs/zion-ui'
import { NoMemories } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function MemoriesNoResults({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoMemories,
    heading: small
      ? t('one-search.memories.action', 'Memories')
      : t('one-search.memories.no-memories', 'Sorry, we didn’t find any memories.'),
    subheading: small
      ? t(
          'one-search.memories.preview-try-advanced-search',
          'Other users have uploaded these photos, stories, documents, or audio files. To find Memories, try the advanced search. Be sure to include names of people or places.'
        )
      : t(
          'one-search.memories.try-advanced-search',
          'Give it another try with an advanced search. Be sure to include names of people or places.'
        ),
    testid: 'memories-empty',
  }

  return small ? (
    <SmallNoResults {...data}>
      <Button external to="/photos/find" linkName="Memories:No Results:Search">
        {t('one-search.memories.memories-search.link', 'Memories Search')}
      </Button>
    </SmallNoResults>
  ) : (
    <BigNoResults {...data}>
      <Button external to="/photos/find" linkName="Memories:No Results:Search">
        {t('one-search.memories.memories-search.link', 'Memories Search')}
      </Button>
    </BigNoResults>
  )
}
