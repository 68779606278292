import React from 'react'
import { usePageView } from '@fs/zion-analytics'
import { useQueryParams } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { LayoutBand, Row, colors, Separator, H2 } from '@fs/zion-ui'
import { i18n } from '@fs/zion-locale' // Import i18n for language
import { useSurnamePage } from '../hooks/useSurname'
import SurnameDefinition from './SurnameDefinition'
import CountriesSection from './CountriesSection'
import DiscoverySearchSection from './DiscoverySearchSection'
import TreeSection from './TreeSection'
import CollectionsSection from './CollectionsSection'
import SurnameSearchAgainSection from './SurnameSearchAgainSection'
import SurnameLoading from '../SurnameLoading'
import SurnameError from '../../../ResultsPage/SurnameTab/SurnameError'
import SurnameResultsPageHead from './SurnameResultsPageHead'
import { formatNameDisplay } from '../../../ResultsPage/utils'
import AccessAccountSection from '../Search/sections/AccessAccountSection'

const Results = () => {
  usePageView({ page_detail: 'Surname Results' })
  const [t] = useTranslation()
  const { query } = useQueryParams()
  const { surname, similar } = query
  const currentSurname = formatNameDisplay(similar) || formatNameDisplay(surname)
  const { recordsData, surnameData, treeData } = useSurnamePage(currentSurname)
  const { data: surnameInfo, isError: surnameError, isLoading: surnameLoading } = surnameData || {}
  const { isLoading: recordsLoading, isSuccess: recordsSuccess, isError: recordsError } = recordsData || {}
  const stateLazy = { isLoading: recordsLoading, isSuccess: recordsSuccess, isError: recordsError }
  const headingSearchSurname = t('surname.results.search-surname.header', 'Search for another last name')

  // Generate infographic URL dynamically
  const infographicImage = `${window.location.origin}/service/home/fig/lang/${i18n.language}/surname/v5/${encodeURI(
    currentSurname
  )}.png`
  return (
    <>
      <SurnameResultsPageHead surname={surname} infographicImage={infographicImage} />
      {surnameLoading ? (
        <SurnameLoading />
      ) : (
        <>
          <SurnameDefinition
            attribution={surnameInfo?.attribution}
            definition={surnameInfo?.htmlDefinition}
            hasSurnameInfo={!!surnameInfo}
            surname={currentSurname}
            variants={surnameInfo?.variants}
          />
          <CountriesSection surname={currentSurname} countries={surnameInfo?.countries} />
        </>
      )}
      <DiscoverySearchSection
        historicalRecordsCount={recordsData?.data?.count}
        state={stateLazy}
        surname={currentSurname}
      />
      <TreeSection state={treeData} surname={currentSurname} />
      <CollectionsSection surname={currentSurname} />
      <LayoutBand color={colors.gray02} data-testid="section-searchagain">
        <Separator size="xxl" />
        <H2 size="H3" centered>
          {headingSearchSurname}
        </H2>
        <Row data-testid="section-searchagain" alignX="center" gutters="none">
          <SurnameSearchAgainSection heading={headingSearchSurname} standard />
        </Row>
        <Separator size="lg" />
      </LayoutBand>
      <AccessAccountSection linkNamePrefix="SurnameResults:AccessAccount" />
      {surnameError ? <SurnameError /> : null}
    </>
  )
}

export default Results
