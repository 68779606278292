import React from 'react'
import { Card, Divider, colors, Bleed } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { useUser } from '@fs/zion-user'
import { i18n } from '@fs/zion-locale'
import { useBottomSection } from './hooks'
import { Event, Relationship, Memories, PersonBlock } from './components'

const bottomCss = css`
  background-color: ${colors.gray02};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: 240px;
  padding: 0 16px 16px;
`

const PlaceRelationsMemories = ({ person = {}, ...rest }) => {
  const showMemories = Boolean(person?.artifacts?.total) || false
  return (
    <div {...rest} style={{ flexGrow: 1 }} data-testid="person-data-2">
      <Divider />
      <div css={bottomCss}>
        <Event person={person} />
        <Relationship person={person} hasMemories={showMemories} />
        <Memories memories={person?.artifacts?.artifactList} total={person?.artifacts?.total} />
      </div>
    </div>
  )
}

function getPersonURL(pid, isLoggedIn, locale) {
  if (!pid || !locale) return ''
  if (!isLoggedIn) {
    return `https://ancestors.familysearch.org/${locale}/${pid}`
  }
  return `/tree/person/details/${pid}`
}

export default function ProfileCard({ person, external, personUrl }) {
  const { signedIn } = useUser()
  const shouldShowBottomSection = useBottomSection(person)
  const to = personUrl || getPersonURL(person?.identifier, signedIn, i18n.language)

  const urlProps = !to ? {} : { to, external, linkName: 'Ancestor Card' }
  return (
    <Card {...urlProps}>
      <PersonBlock person={person} />
      {shouldShowBottomSection && <Bleed as={PlaceRelationsMemories} person={person} sides bottom />}
    </Card>
  )
}
