import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import searchPerson from '../images/searchPerson.svg'
import emptyRecords from '../images/emptyRecords.svg'
import ftProfileCards from '../images/ft-profile-cards.svg'
import memoriesGraphic from '../images/memoriesGraphic.svg'
import noSurname from '../images/noSurname.svg'
import noData from '../images/noData.svg'

const basicCss = css`
  max-width: 100%;
`

export function SearchPerson({ ...props }) {
  const [t] = useTranslation()

  return (
    <img
      src={searchPerson}
      alt={t('one-search.spyglass-person.alt', 'person with spyglass')}
      css={basicCss}
      {...props}
    />
  )
}

export function NoRecords({ ...props }) {
  const [t] = useTranslation()

  return <img src={emptyRecords} alt={t('one-search.no-records.alt', 'no records')} css={basicCss} {...props} />
}

export function NoTree({ ...props }) {
  const [t] = useTranslation()

  return <img src={ftProfileCards} alt={t('one-search.profile-cards.alt', 'Profile Cards')} {...props} />
}

export function NoMemories({ ...props }) {
  const [t] = useTranslation()

  return <img src={memoriesGraphic} alt={t('one-search.no-memories.alt', 'no memories')} css={basicCss} {...props} />
}

export function NoSurname({ ...props }) {
  const [t] = useTranslation()

  return <img src={noSurname} alt={t('one-search.no-surname.alt', 'no surname')} css={basicCss} {...props} />
}

export function NoData({ ...props }) {
  const [t] = useTranslation()

  return <img src={noData} alt={t('one-search.no-data.alt', 'no data')} css={basicCss} {...props} />
}

export default null
