import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph, Row, Separator, useContainerWidth } from '@fs/zion-ui'

const SmallNoResults = ({ Image, heading, subheading, testid, children }) => {
  const atWidth = useContainerWidth()
  return (
    <div css={{ maxWidth: 350 }} data-testid={`no-results-small-${testid}`}>
      <Row alignX="center">
        <Image css={{ height: 58 }} />
      </Row>
      <Separator size="xxs" />
      <Paragraph centered size="sm">
        <strong>{heading}</strong>
      </Paragraph>
      {atWidth({
        default: (
          <Paragraph centered size="xs">
            {subheading}
          </Paragraph>
        ),
        sm: (
          <Paragraph centered size="sm">
            {subheading}
          </Paragraph>
        ),
      })}
      <Separator size="sm" />
      <Row alignX="center">{children}</Row>
    </div>
  )
}

SmallNoResults.propTypes = {
  Image: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  testid: PropTypes.string,
}

export default SmallNoResults
