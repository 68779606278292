export function getThumbnailUrl(memory) {
  let thumbnailUrl = memory.thumbnailUrl
  if (!thumbnailUrl) {
    thumbnailUrl = memory?.associatedMedia?.find((artifact) => {
      return artifact.thumbnailUrl
    })?.thumbnailUrl
  }
  return thumbnailUrl
}

export function getMemoryTileType(memory) {
  let tileType = memory.memoryType
  if (memory.memoryType === 'PHOTO') {
    tileType = memory?.associatedMedia?.find((artifact) => {
      return artifact.memoryType === 'AUDIO'
    })
      ? 'AUDIO'
      : 'PHOTO'
  }
  return tileType ? tileType.toLowerCase() : 'photo'
}

export function calculateAge(birth, death) {
  const cleanDateString = (dateString) => dateString.replace(/^[aA]\+/, '').replace('+', '')

  if (!birth || !death) return undefined
  const birthDate = new Date(cleanDateString(birth))
  const deathDate = new Date(cleanDateString(death))
  const msAge = deathDate.getTime() - birthDate.getTime()
  const age = Math.floor(msAge / (1000 * 3600 * 24 * 365))
  if (Number.isNaN(age)) return undefined
  return Math.max(0, age) // Prevent showing negative numbers
}

export function getTreeDataPerson(person) {
  const nameForm = person.nameForms?.[0]
  const nameFormsObject = {
    familyPart: nameForm?.parts?.find((name) => name.type.includes('Surname'))?.value,
    fullText: nameForm?.fullText,
    givenPart: nameForm?.parts?.find((name) => name.type.includes('Given'))?.value,
    lang: nameForm?.lang,
    prefixPart: nameForm?.parts?.find((name) => name.type.includes('Prefix'))?.value,
    suffixPart: nameForm?.parts?.find((name) => name.type.includes('Suffix'))?.value,
  }
  return {
    name: person?.name,
    nameConclusion: {
      details: {
        fullText: nameForm?.fullText,
        nameForms: [nameFormsObject],
        style: nameForm?.order,
      },
    },
  }
}

export const getPlace = (person) => {
  return person.birthPlace || person.christeningPlace || person.deathPlace || person.burialPlace
}

export const getRelative = (person) => {
  return person?.spouse?.[0] || person?.parent?.[0] || person?.children?.[0]
}
