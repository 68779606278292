import React from 'react'
import { DataBlock, colors } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

const titleColor = css`
  color: ${colors.transparent.gray70};
`

const objectValuesToArray = (object) =>
  Object.keys(object).map((key) => (Array.isArray(object[key]) ? object[key].join(', ') : object[key]))

export default function RecordBlock({ type, ...info }) {
  const [t] = useTranslation()

  const recordTypeEnum = {
    'http://gedcomx.org/Birth': t('one-search.birth.label', 'Birth'),
    'http://gedcomx.org/Death': t('one-search.death.label', 'Death'),
    'http://gedcomx.org/Burial': t('one-search.burial.label', 'Burial'),
    'http://gedcomx.org/Residence': t('one-search.residence.label', 'Residence'),
    'http://gedcomx.org/MaritalStatus': t('one-search.marriage.label', 'Marriage'),
    'http://familysearch.org/types/facts/PreviousResidence': t('one-search.residence.label', 'Residence'),
    'http://gedcomx.org/Census': t('one-search.census.label', 'Census'),
    fatherNames: t('one-search.father.label', 'Father'),
    motherNames: t('one-search.mother.label', 'Mother'),
    spouseNames: t('one-search.spouse.label', 'Spouse'),
    childrenNames: t('one-search.children.label', 'Children'),
    otherNames: t('one-search.other.label', 'Other'),
  }

  return recordTypeEnum[type] ? (
    <DataBlock
      as="h4"
      data-testid="record-block"
      heading={<span css={titleColor}>{recordTypeEnum[type]}</span>}
      data={objectValuesToArray(info).join('\n')}
    />
  ) : null
}
