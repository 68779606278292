import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@fs/zion-ui'
import { NoTree } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function TreeNoResults({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoTree,
    heading: small
      ? t('one-search.tree-profiles.action', 'Family Tree Profiles')
      : t('one-search.tree-profiles.no-profiles', 'Sorry, we didn’t find any Family Tree profiles.'),
    subheading: small
      ? t(
          'one-search.tree-profiles.preview-try-advanced-search',
          'Other users have added these ancestors to the community Family Tree. To find Family Tree profiles, try the advanced search. Include additional life events or relationships.'
        )
      : t(
          'one-search.tree-profiles.try-advanced-search',
          'Give it another try with an advanced search. Include additional life events or relationships.'
        ),
    testid: 'tree-empty',
  }

  return small ? (
    <SmallNoResults {...data}>
      <Button external to="/search/tree" linkName="Tree:No Results:Search">
        {t('one-search.family-tree-search.link', 'Family Tree Search')}
      </Button>
    </SmallNoResults>
  ) : (
    <BigNoResults {...data}>
      <Button external to="/search/tree" linkName="Tree:No Results:Search">
        {t('one-search.family-tree-search.link', 'Family Tree Search')}
      </Button>
    </BigNoResults>
  )
}
