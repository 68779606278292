import React from 'react'
import { LayoutBand, Skeleton, useContainerWidth, Separator, FlowGrid } from '@fs/zion-ui'

const TreeOverviewPageSkeleton = () => {
  // Initiate state variables and hooks
  const atWidth = useContainerWidth()

  return (
    <LayoutBand>
      <Separator size="sm" />
      <Skeleton.Image height={800} />

      <div style={{ marginTop: -140, padding: '0 40px' }}>
        <FlowGrid columnCount={atWidth({ md: 2 })}>
          <Skeleton.Image height={600} style={{ minWidth: 350 }} />
          <Skeleton.Image height={600} style={{ minWidth: 350 }} />
        </FlowGrid>
      </div>
      <Separator />
      <Skeleton.Image height={375} />
      <Skeleton.Image height={375} />
      <Skeleton.Image height={375} />
      <Skeleton.Image height={375} />
    </LayoutBand>
  )
}

export default TreeOverviewPageSkeleton
