import React from 'react'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { baseUrl } from '@fs/zion-config'
import { PageHead } from '@fs/zion-head'
import surnameSearchOgImage from '../assets/search-share.jpg'
import { formatName } from '../../../ResultsPage/utils'

export default function SurnameResultsPageHead({ surname, infographicImage, children }) {
  const [t] = useTranslation()
  const { nameDisplay } = formatName(surname)
  const title = t('surname.results.meta.title', '{surname} Name Meaning and {surname} Family History at FamilySearch', {
    surname: nameDisplay,
  })
  const description = t('surname.results.meta.description', 'Search for and discover information on your last name')
  const ogDescription = t('surname.results.og.description', 'Search for and discover information on your last name')
  const ogImageAlt = infographicImage
    ? t('surname.results.og.imageAlt', 'Infographic showing {surname} name meaning and family history', {
        surname: nameDisplay,
      })
    : t('surname.app.og.imageAlt', 'FamilySearch company logo with a magnifying glass icon.')
  const canonicalUrl = `${baseUrl || ''}/${i18n.language}/surname?surname=${surname}`

  // Use infographicImage if provided, otherwise fall back to default
  const imageUrl = infographicImage || surnameSearchOgImage
  const imageWidth = infographicImage ? 1146 : 1200 // Adjust based on your actual sizes
  const imageHeight = infographicImage ? 600 : 628

  return (
    <PageHead
      title={title}
      description={description}
      socialShareDescription={ogDescription}
      url={canonicalUrl}
      image={{ url: imageUrl, alt: ogImageAlt, width: imageWidth, height: imageHeight }}
      type="website"
      twitterCard="summary_large_image"
      includeHreflang={false}
    >
      {children}
    </PageHead>
  )
}
