import React from 'react'
import { useTranslation } from 'react-i18next'
import { baseUrl } from '@fs/zion-config'
import { i18n } from '@fs/zion-locale'
import { PageHead } from '@fs/zion-head'
import surnameSearchOgImage from '../assets/search-share.jpg'

export function getDefaultSurnamePageMetaData(t) {
  const title = t('surname.search.meta.title', 'Search your Surname and find its meaning')
  const description = t(
    'surname.search.meta.description',
    'Enter your surname and begin learning its origins, your potential family lineage, and where your ancestors most likely came from. Find out more today.'
  )
  const ogTitle = t('surname.app.og.title', 'Find your last name’s meaning and origin.')
  const ogDescription = t('surname.app.og.description', 'Begin learning more about yourself and your heritage.')
  return {
    title,
    omitSiteName: true,
    meta: {
      title,
      description,
      canonicalUrl: `${baseUrl || ''}/${i18n.language}/surname`,
      ogTitle,
      ogDescription,
      ogImage: surnameSearchOgImage,
    },
  }
}

export default function SurnameSearchPageHead({ children }) {
  const [t] = useTranslation()

  const title = t('surname.search.meta.title', 'Search your Surname and find its meaning')
  const description = t(
    'surname.search.meta.description',
    'Enter your surname and begin learning its origins, your potential family lineage, and where your ancestors most likely came from. Find out more today.'
  )
  const ogDescription = t('surname.app.og.description', 'Begin learning more about yourself and your heritage.')
  const ogTitle = t('surname.app.og.title', 'Find your last name’s meaning and origin.')
  const ogImageAlt = t('surname.app.og.imageAlt', 'FamilySearch company logo with a magnifying glass icon.')
  const canonicalUrl = `${baseUrl || ''}/${i18n.language}/surname/`

  return (
    <PageHead
      title={title}
      socialShareTitle={ogTitle}
      description={description}
      socialShareDescription={ogDescription}
      url={canonicalUrl}
      image={{ url: surnameSearchOgImage, alt: ogImageAlt, width: 1200, height: 628 }}
      type="website"
      includeHreflang
      twitterCard="summary_large_image"
    >
      {children}
    </PageHead>
  )
}
