import React from 'react'
import { ResponsiveTable, colors } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { getCollectionDetails } from '@fs/zion-gedcomx'
import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import { LowResultsHelpRow, HighResultsHelpRow } from './HelpRows'
import useTableColumns from './useTableColumns'
import RecordsTableRow from './RecordsTableRow'
import RecordsError from './RecordsError'
import EmptyRecords from './EmptyRecords'
import RecordTableLoading from './RecordTableLoading'

const sideSpacing = css`
  padding 0 12px;
  margin: 0 -12px;
  background-color: ${colors.gray00};
`

/**
 * Gets the 1950 census discovery url if the record is a 1950 census record, the user's language is english, and the discoverySearchLink flag is on.
 * Otherwise it will return the originalUrl that was passed into the function.
 * @param {String} originalUrl - the url for HR search result record details pages
 * @param {Object} originalRecord - the original record that has the id and the gedcomx
 * @param {Object} discoverySearchLink - the feature flag that lets us know if we want to use the discovery url or the original url
 * @returns {String} - the url for the record (either HR search result record details pages or 1950 census discovery pages)
 */
function getDiscoveryUrl(originalUrl, originalRecord, discoverySearchLink) {
  let url = originalUrl
  if (discoverySearchLink.isOn && i18n.language === 'en') {
    const { collectionId } = getCollectionDetails(originalRecord.content.gedcomx)
    if (collectionId === '4464515') {
      const ark = originalRecord.id
      url = `/eurona/1950/search/collection/${collectionId}/discovery/${ark}`
    }
  }
  return url
}

function ResponsiveRecordsTable({ records, total, isPreview }) {
  const columns = useTableColumns()

  const viewableRecords = isPreview ? records?.slice(0, 4) : records

  const discoverySearchLink = useFeatureFlag('eurona_1950census_discoverySearchLink')

  const rows = viewableRecords.map(({ url, ...props }) => {
    const newUrl = getDiscoveryUrl(url, props.originalRecord, discoverySearchLink)

    return <RecordsTableRow key={url} url={newUrl} {...props} />
  })

  if (!isPreview) {
    if (total < 11) {
      if (total < 3) {
        rows.push(<LowResultsHelpRow key="thehelper" />)
      } else {
        rows.splice(total - 2, 0, <LowResultsHelpRow key="thehelper" notBottom />)
      }
    } else if (viewableRecords.length < 3) {
      rows.push(<HighResultsHelpRow key="thehelper" />)
    } else {
      rows.splice(viewableRecords.length - 2, 0, <HighResultsHelpRow key="thehelper" notBottom />)
    }
  }

  return (
    <ResponsiveTable {...columns} data-testid="hr-results">
      {rows}
    </ResponsiveTable>
  )
}

export default function RecordsTable({
  records,
  isLoading = false,
  isError = false,
  index = 0,
  total,
  count = 12,
  isPreview,
}) {
  if (isError) {
    return <RecordsError />
  }
  if (isLoading) {
    return <RecordTableLoading count={count} />
  }
  if (records?.length) {
    return (
      <div css={sideSpacing}>
        <ResponsiveRecordsTable records={records} index={index} total={total} isPreview={isPreview} />
      </div>
    )
  }
  return <EmptyRecords />
}
