import React from 'react'
import { useTranslation } from 'react-i18next'
import { Bleed, Card, Divider, FancyImage, Separator, Paragraph, Button, H3 } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import PropTypes from 'prop-types'
import AdvancedSearchCardImg from './AdvancedSearchCard.svg'

export default function AdvancedSearchCard({
  top: { linkText: topLinkText, to: topUrl, text: topText, external: topExternal = false, linkName: topLinkName } = {},
  bottom: {
    linkText: bottomLinkText,
    to: bottomUrl,
    text: bottomText,
    external: bottomExternal = false,
    linkName: bottomLinkName,
  } = {},
  hasFewResults,
  loggedOut,
}) {
  const [t] = useTranslation()

  if (loggedOut) {
    return (
      <Card data-testid="memories-entry-search">
        <Bleed top sides>
          <FancyImage alt="*" src={AdvancedSearchCardImg} height="150px" />
        </Bleed>
        <Separator size="xxs" />
        <H3 size="H5" supplementary>
          {t('one-search.advanced-card.logged-out.header', 'Discover even more')}
        </H3>
        <Separator size="nano" />
        <Paragraph size="xs">
          {t('one-search.login-to-see-more.blurb', 'Create a free account to see the rest of the search results.')}
        </Paragraph>
        <Separator size="xs" />
        <Button
          emphasis="medium"
          to={`/register/?state=${encodeURIComponent(window.location.href)}`}
          external
          linkName="Discovery Search:Create Account"
        >
          {t('one-search.create-free-account.action', 'Create a Free Account')}
        </Button>
        <Separator size="xxs" />
        <Button
          to={`/auth/familysearch/login?returnUrl=${encodeURIComponent(window.location.href)}`}
          external
          linkName="Discovery Search:Sign In"
        >
          {t('common-ui:sign-in.action', 'Sign In')}
        </Button>
      </Card>
    )
  }

  return (
    <Card data-testid="memories-entry-search">
      <Bleed top sides>
        <FancyImage alt="*" src={AdvancedSearchCardImg} height="140px" style={{ backgroundPosition: 'bottom' }} />
      </Bleed>
      <Separator size="xxs" />
      <H3 size="H5" supplementary>
        {hasFewResults
          ? t('one-search.low-results.label', 'Not getting many results?')
          : t('one-search.high-results.label', 'Not finding what you are looking for in the quick search?')}
      </H3>
      <Separator size="nano" />
      <Paragraph size="xs" secondary>
        {topText}
      </Paragraph>
      <Separator size="xs" />
      <Link to={topUrl} external={topExternal} linkName={topLinkName} style={{ textDecoration: 'none' }}>
        {topLinkText}
      </Link>
      {bottomText && (
        <>
          <Separator size="xxs" />
          <Divider />
          <Separator size="xs" />
          <Paragraph size="xs">{bottomText}</Paragraph>
          <Paragraph size="xs">
            <Link
              to={bottomUrl}
              external={bottomExternal}
              linkName={bottomLinkName}
              style={{ textDecoration: 'none' }}
              wayfinding
            >
              {bottomLinkText}
            </Link>
          </Paragraph>
        </>
      )}
    </Card>
  )
}
AdvancedSearchCard.propTypes = {
  top: PropTypes.shape({
    linkText: PropTypes.string,
    to: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
  }),
  bottom: PropTypes.shape({
    linkText: PropTypes.string,
    to: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
  }),
  hasFewResults: PropTypes.bool,
}
