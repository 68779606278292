import React, { useState, useEffect, Fragment } from 'react'
import DOMPurify from 'dompurify' // This is already being used in rootstech-connect and discovery-react
import { useTranslation } from 'react-i18next'
import { ArrowCaret, ContentPrint } from '@fs/zion-icon'
import {
  Button,
  Paragraph,
  Row,
  Separator,
  useContainerWidth,
  WhiteTheme,
  LayoutBand,
  usePageWidth,
  Expander,
  ExpanderContent,
  Bleed,
  H1,
  H2,
  Grid,
  colors,
} from '@fs/zion-ui'
import { css } from '@emotion/core'
import { useQueryParams } from '@fs/zion-router'
import worldMap from '../assets/WorldMap.webp'
import SurnameSearchAgainSection from './SurnameSearchAgainSection'
import SimilarSurnames from './SimilarSurnames'
import { formatNameDisplay } from '../../../ResultsPage/utils'
import SurnamePageSocialBar from '../SurnamePageSocialBar'

const whiteTextBaseCss = css`
  margin: 0 auto;
`

const positionXXS = css`
  padding: 0 16px;
`

const positionSM = css`
  padding: 0 20px;
`

const positionMD = css`
  max-width: 1100px;
`

const printButtonCss = css`
  inset-inline-start: 20px;
  position: absolute;
  top: 8px;
`

const printButtonDesktopCss = css`
  inset-inline-start: 28px;
  top: 28px;
`

const textParagraph = css`
  & p {
    max-width: 100%;
  }
`
const worldBackgroundCss = css`
  position: relative;
  background-image: url(${worldMap});
  background-position: top; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-size: cover; /* Adjust the image to fit within the element */
`

const boldFirstPart = (definition) => {
  if (!definition) return ''
  return definition.replace(/<p>([^:]+:)/g, '<p><b>$1</b>')
}

export default function SurnameDefinition({ attribution, definition, hasSurnameInfo, surname, variants }) {
  const atWidth = useContainerWidth()
  const currentDefinition = boldFirstPart(definition)
  const [arrayParagraphs, setArrayParagraphs] = useState([])
  const [t] = useTranslation()
  const isMobile = usePageWidth()({ default: true, md: false })
  const [surnameIsOpen, setSurnameIsOpen] = useState(false)
  const [surnameDoesExpand, setSurnameDoesExpand] = useState()
  const { setQuery } = useQueryParams()
  const position = atWidth({
    default: positionXXS,
    sm: positionSM,
    md: positionMD,
  })

  useEffect(() => {
    if (definition) {
      const sanitizedParagraphs = currentDefinition.replaceAll('<p>', '').split('</p>')
      setArrayParagraphs(sanitizedParagraphs)
    }
  }, [currentDefinition, definition])

  const nameDisplay = formatNameDisplay(surname)

  const socialBarStyle = { color: colors.gray02, marginInlineStart: 0 }
  const socialShareAnalytics = {
    link_name: 'ResultsSocialBarShare',
    link_page: 'Surname Results',
    link_app: 'one-search',
  }

  return (
    <div data-testid="section-surnameresult" css={worldBackgroundCss}>
      <LayoutBand
        marginX={isMobile ? 'md' : 'lg'}
        marginY={isMobile ? 'md' : 'xl'}
        color={colors.green60}
        css={worldBackgroundCss}
      >
        <Separator size={isMobile ? 'xl' : 'xxs'} />
        <div css={[whiteTextBaseCss, position]}>
          <WhiteTheme>
            <div css={[printButtonCss, !isMobile && printButtonDesktopCss]}>
              {hasSurnameInfo && (
                <Button
                  Icon={ContentPrint}
                  emphasis={isMobile ? 'low' : 'medium'}
                  linkName="SurnameDefinition: Print"
                  data-testid="surname-results-print-button"
                  aria-label={t('common-ui:print.action', 'Print')}
                  onClick={() => {
                    setQuery({ print: true })
                  }}
                >
                  {!isMobile && t('common-ui:print.action', 'Print')}
                </Button>
              )}
            </div>
            <Separator size={atWidth({ default: 'sm', md: 'xl' })} />
            <Separator size={atWidth({ default: 'hide', md: 'sm' })} />
            <H1 centered size="H2">
              {t('surname.results.header', '{surname} Family History', {
                surname: nameDisplay,
              })}
            </H1>
            {definition && <Separator size={atWidth({ default: 'xl', md: 'xl' })} />}

            <Grid columns="12" guttersX={atWidth({ default: 'sm', md: 'xl', xl: 'xxl' })}>
              <div
                columns={atWidth({ default: 12, md: 7 })}
                borderEnd={atWidth({ default: false, md: true })}
                data-testid="left-side"
              >
                {definition && (
                  <>
                    <H2 size="H5">
                      {t('surname.results.subheader', '{surname} Name Meaning', {
                        surname: nameDisplay,
                      })}
                    </H2>
                    <Separator size={atWidth({ default: 'md', md: 'xs' })} />
                    <Expander open={surnameIsOpen} setOpen={setSurnameIsOpen}>
                      <ExpanderContent
                        collapsedHeight={116}
                        setDoesExpand={setSurnameDoesExpand}
                        testid="definition-expander"
                      >
                        <Bleed key={currentDefinition}>
                          <div css={textParagraph}>
                            {definition
                              ? arrayParagraphs.map?.((paragraph, i) => {
                                  const key = ` key${i}`
                                  return (
                                    <Fragment key={key}>
                                      <Paragraph
                                        size="sm"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraph) }}
                                        data-testid="surnamedefinition"
                                      />
                                    </Fragment>
                                  )
                                })
                              : surname && (
                                  <Paragraph size="sm">
                                    {t('surname.results.no-name-meaning', 'We couldn’t find a meaning for this name.')}
                                  </Paragraph>
                                )}
                          </div>
                        </Bleed>
                      </ExpanderContent>
                    </Expander>
                    {surnameDoesExpand && (
                      <>
                        <Separator size={!surnameIsOpen ? atWidth({ default: 'md', md: 'sm' }) : 'hide'} />
                        <Row>
                          <Button
                            aria-expanded={!!surnameIsOpen}
                            aria-label={
                              !surnameIsOpen
                                ? t('surname.results.surname-description.link.show-more', 'Show more')
                                : t('surname.results.surname-description.link.show-less', 'Show Less')
                            }
                            data-testid="surname-definition-view-more"
                            onClick={() => {
                              setSurnameIsOpen(!surnameIsOpen)
                            }}
                            linkName={`SurnameDefinition:${surnameIsOpen ? 'ViewMore' : 'ViewLess'}`}
                          >
                            {!surnameIsOpen
                              ? t('surname.results.surname-description.link.show-more', 'Show more')
                              : t('surname.results.surname-description.link.show-less', 'Show Less')}
                            {!surnameIsOpen ? <ArrowCaret direction="down" /> : <ArrowCaret direction="up" />}
                          </Button>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </div>
              <div columns={atWidth({ default: 12, md: 5 })} data-testid="right-side">
                {atWidth({ default: <Separator size="md" />, md: null })}
                {variants && <SimilarSurnames variants={variants} />}
                <SurnamePageSocialBar
                  noShrinkX
                  customStyles={socialBarStyle}
                  surname={surname}
                  analytics={socialShareAnalytics}
                />
              </div>
            </Grid>
          </WhiteTheme>
          <Separator size={atWidth({ default: 'hide', md: surnameDoesExpand ? 'xl' : 'md' })} />
          <Row alignX="center">
            <SurnameSearchAgainSection
              growX={isMobile}
              heading={t('surname.results.try-another.header', 'Want to search a different name?')}
            />
          </Row>
          {attribution && (
            <WhiteTheme>
              <Paragraph centered size="xs" data-testid="attribution">
                <span>{attribution}</span>
              </Paragraph>
            </WhiteTheme>
          )}
        </div>
        <Separator size={isMobile ? 'lg' : 'xs'} />
      </LayoutBand>
    </div>
  )
}
