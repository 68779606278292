import React from 'react'
import { i18n } from '@fs/zion-locale'
import { usePageView } from '@fs/zion-analytics'
import { useTranslation } from 'react-i18next'
import PageHead from '../../../components/PageHead'
import SurnamesSearchSection from '../../../components/SharedSurnames/SurnamesSearchSection'
import sharedSurnamesSearchOgImage from '../../../components/SharedSurnames/assets/searchInfographic.jpg'
import useUsabilla from '../../../hooks/useUsabilla'

const Search = () => {
  usePageView({ page_detail: 'Shared Surnames Search' })
  const [t] = useTranslation()
  useUsabilla() // Adds translated Usabilla feedback button to right side of viewport.

  const title = t('shared-surnames.search.meta.title', 'Last Name Popularity Match')
  const description = t(
    'shared-surnames.search.meta.description',
    'Find out which countries your last name and your friend’s last name are most likely to be found in together.'
  )
  const ogTitle = t('shared-surnames.app.og.title', 'Do you share a last name connection with your friends?')
  const ogDescription = t(
    'shared-surnames.app.og.description',
    'Find out which countries your last name and your friend’s last name are most likely to be found in together.'
  )
  const metaData = {
    title,
    meta: {
      title,
      description,
      canonicalUrl: `https://www.familysearch.org/${i18n.language}/surnames`,
      ogTitle,
      ogDescription,
      ogImage: sharedSurnamesSearchOgImage,
    },
  }

  return (
    <>
      <PageHead {...metaData} />
      <SurnamesSearchSection />
    </>
  )
}

export default Search
