import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@fs/zion-ui'
import { NoSurname } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function EmptySurname({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoSurname,
    heading: small
      ? t('one-search.surname-empty.heading', 'Sorry, we didn’t find any last name information.')
      : t('one-search.surname-empty.heading', 'Sorry, we didn’t find any last name information.'),
    subheading: small
      ? t('one-search.surname-empty.subheading', 'Try searching for another name.')
      : t('one-search.surname-empty.subheading', 'Try searching for another name.'),
    testid: 'surname-empty',
  }

  return small ? (
    <SmallNoResults {...data}>
      <Button to="." external linkName="Surname:Quick Search">
        {t('one-search.quick-search.action', 'Quick Search')}
      </Button>
    </SmallNoResults>
  ) : (
    <BigNoResults {...data}>
      <Button to="." external linkName="Surname:Quick Search">
        {t('one-search.quick-search.action', 'Quick Search')}
      </Button>
    </BigNoResults>
  )
}
