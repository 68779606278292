import {
  Bleed,
  IconButton,
  LayoutBand,
  MarginBox,
  Row,
  Separator,
  useContainerWidth,
  WhiteTheme,
  H1,
  Paragraph,
  colors,
} from '@fs/zion-ui'
import ZionForm, { FormTextField, useZionForm } from '@fs/zion-form'
import React from 'react'
import { css } from '@emotion/core'
import { useQueryParams } from '@fs/zion-router'
import { MenuSearch } from '@fs/zion-icon'
import { useTranslation } from 'react-i18next'
import layoutBandBackground from '../../assets/WorldMap.webp'
import { getSurnameResolver } from '../surnameResolver'
import { formatNameParam } from '../../../../ResultsPage/utils'
import { NAV_IDS } from './StickyNavSurnameSection'
import SurnamePageSocialBar from '../../SurnamePageSocialBar'

const layoutBandCss = css`
  position: relative;
  background-image: url(${layoutBandBackground});
  background-position: top; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-size: cover; /* Adjust the image to fit within the element */
`
const buttonContainerCss = css`
  opacity: 100;
  background: ${colors.gray00};
  border-radius: 24px;
`

export default function SurnameSearchSection() {
  const [t] = useTranslation()
  const { setQuery } = useQueryParams()
  const [surname, setSurname] = React.useState()
  const atWidth = useContainerWidth()

  React.useEffect(() => {
    let isCurrent = true
    if (isCurrent && surname) setQuery({ surname })

    return () => {
      isCurrent = false
    }
  }, [surname, setQuery])
  const defaultValues = { yourLastName: '' }
  const methods = useZionForm({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: getSurnameResolver(t),
  })

  const {
    formState: { isValid },
  } = methods

  const handleFormSubmit = ({ yourLastName }) => {
    setSurname(formatNameParam(yourLastName))
  }
  const atSize = useContainerWidth()
  const analytics = {
    link_name: 'SurnameSearchSocialBarShare',
    link_page: 'Surname SEO:Search',
    link_app: 'one-search',
  }
  const minHeight = 0
  return (
    // <MarginBox marginX="md" marginY="nano" style={{ background: colors.green60, paddingTop: 50 }}>
    <LayoutBand
      id={NAV_IDS.surnameSearchMain}
      alignY="middle"
      css={layoutBandCss}
      maxWidth="xl"
      minHeight={minHeight}
      data-testid="section-surnamesearch"
      style={{ minWidth: 320 }}
      color={colors.green60}
    >
      <WhiteTheme data-testid="surname-search-text">
        <Row alignX="center" style={{ width: '100%' }}>
          <header style={{ maxWidth: 650 }}>
            <Separator size="xxl" />
            <H1 centered size={atSize({ default: 'H3', sm: 'H2' })}>
              {t('surname.surname-search-section.header', "Find your last name's meaning and origin.")}
            </H1>
            <Separator size="sm" />
            <MarginBox marginY="nano" marginX="xxl">
              <Paragraph centered size="md" secondary>
                {t(
                  'surname.surname-search-section.subheader',
                  'Your surname can unlock discoveries about your heritage.'
                )}
              </Paragraph>
            </MarginBox>
          </header>
        </Row>
      </WhiteTheme>
      <section data-testid="surname-search-section">
        <Separator size="lg" />
        <ZionForm autoComplete="off" methods={methods} onSubmit={handleFormSubmit}>
          <Row
            alignX="center"
            alignY="middle"
            alignItemsY="middle"
            gutters="md"
            style={{ '--button-text-color': 'var(--gray90)' }}
          >
            <Bleed sides>
              {/* eslint-disable-next-line @fs/zion/logical-over-directional */}
              <div style={{ margin: '16px 4px 0 24px', width: atWidth({ default: 200, sm: 282, md: 350, xl: 350 }) }}>
                <FormTextField
                  autoComplete="family-name"
                  aria-label={t('surname.surname-search-section.your-last-name.label', 'Your Last Name')}
                  name="yourLastName"
                  type="text"
                  data-testid="your-last-name-field"
                  placeholder={t('surname.surname-search-section.your-last-name.placeholder', 'Enter a last name')}
                />
              </div>
            </Bleed>
            <div css={buttonContainerCss}>
              <IconButton
                disabled={!isValid}
                Icon={MenuSearch}
                size="md"
                linkName="SurnameSearchSection: FindNow"
                emphasis="high"
                type="submit"
                data-testid="surnames-search-button"
                title={t('surname.surname-search-section.find-action.label', 'Find Now')}
                color="gray"
              />
            </div>
          </Row>
        </ZionForm>
        <Separator size="xl" />

        <WhiteTheme>
          <Row alignX="center">
            <SurnamePageSocialBar analytics={analytics} />
          </Row>
        </WhiteTheme>
        <Separator size="xl" />
      </section>
    </LayoutBand>
    // </MarginBox>
  )
}
