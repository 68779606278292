import React from 'react'
import { Separator, Paragraph, Row, H2 } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { TreeError } from '../TreeProfileTab'
import TreeNoResults from '../TreeProfileTab/TreeNoResults'
import { RecordsNoResults, RecordsError } from '../RecordsTab'
import { MemoriesNoResults, MemoriesError } from '../MemoriesTab'
import { SurnameNoResults, SurnameError } from '../SurnameTab'
import AllError from './AllError'

export default function ErrorsOrNoResults({
  loadingMemories,
  loadingTree,
  loadingRecords,
  loadingSurname,
  errorMemories,
  errorTree,
  errorRecords,
  errorSurname,
  emptyMemories,
  emptyTree,
  emptyRecords,
  emptySurname,
}) {
  const [t] = useTranslation()
  if (
    loadingMemories ||
    loadingTree ||
    loadingRecords ||
    loadingSurname ||
    (!errorMemories &&
      !errorTree &&
      !errorRecords &&
      !errorSurname &&
      !emptyMemories &&
      !emptyTree &&
      !emptyRecords &&
      !emptySurname)
  ) {
    return null
  }

  const tree = {
    is: errorTree || emptyTree,
    el: errorTree ? <TreeError key="tree_error" small /> : <TreeNoResults key="tree_no_results" small />,
  }
  const records = {
    is: errorRecords || emptyRecords,
    el: errorRecords ? <RecordsError key="records_error" small /> : <RecordsNoResults key="records_no_results" small />,
  }
  const memories = {
    is: errorMemories || emptyMemories,
    el: errorMemories ? (
      <MemoriesError key="memories_error" small />
    ) : (
      <MemoriesNoResults key="memories_no_results" small />
    ),
  }
  const surname = {
    is: errorSurname || emptySurname,
    el: errorSurname ? <SurnameError key="surname_error" small /> : <SurnameNoResults key="surname_no_results" small />,
  }
  const elements = [tree, records, memories, surname].filter(({ is }) => is).map(({ el }) => el)

  return elements.length === 4 ? (
    <AllError />
  ) : (
    <>
      <Separator size="xl" />
      <Row alignX="center" wrap>
        <H2 size="H3" centered>
          {t('one-search.all.more-info.heading', 'We may have more information for you.')}
        </H2>
      </Row>
      <Row alignX="center" wrap>
        <Paragraph size="sm">
          {t('one-search.all.more-info.subheading', 'Try searching these other resources.')}
        </Paragraph>
      </Row>
      <Separator size="xl" />
      <Row alignX="center" gutters="xxl" guttersY="xl" wrap>
        {elements}
      </Row>
    </>
  )
}
