import React from 'react'
import { colors, Button, Image, Row, Separator, useContainerWidth, H3, Paragraph } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { baseUrl } from '@fs/zion-config'
import { i18n } from '@fs/zion-locale'
import searchForAncestors from '../../assets/SearchForAncestors.jpg'
import makeATree from '../../assets/MakeATree.webp'
import getHelp from '../../assets/GetHelp.jpg'
import { NAV_IDS } from './StickyNavSurnameSection'
import SurnameSection from './SurnameSection'

function DiscoveryItem({ src, to, heading, subHeading, actionLabel, actionName }) {
  return (
    <div style={{ maxWidth: 343 }}>
      <Row alignX="center" wrap data-testid="discover-item">
        <div style={{ width: 200, height: 200, position: 'relative', overflow: 'hidden', borderRadius: '50%' }}>
          <Image
            positionY="middle"
            src={src}
            alt={heading}
            height={200}
            maxHeight={200}
            width={200}
            maxWidth={200}
            noCrop
          />
        </div>
        <H3 centered size="H5">
          {heading}
        </H3>
        <Paragraph centered size="sm" secondary>
          {subHeading}
        </Paragraph>
        <Button to={to} linkName={`DiscoverySurnamesSection: ${actionName}`}>
          {actionLabel}
        </Button>
      </Row>
    </div>
  )
}

function SearchForAncestors() {
  const { t } = useTranslation()
  return (
    <DiscoveryItem
      to={`https://ancestors.familysearch.org/${i18n.language}/`}
      src={searchForAncestors}
      heading={t('surname.search-for-ancestors.discover-search.heading', 'Search for ancestors')}
      subHeading={t(
        'surname.search-for-ancestors.discover-search.subHeading',
        'Records can unearth new details and fill in the blanks of your family story.'
      )}
      actionLabel={t('surname.search-for-ancestors.try-search-action.label', 'Try a Search')}
      actionName="TryASearch"
    />
  )
}

function MakeATree() {
  const { t } = useTranslation()
  return (
    <DiscoveryItem
      to={`${baseUrl}/tree/guided/connect-to-tree`}
      src={makeATree}
      heading={t('surname.make-a-tree.make-tree.heading', 'Make a family tree')}
      subHeading={t(
        'surname.make-a-tree.make-tree.subheading',
        "Add what you know to your tree, and we'll help you find more along the way."
      )}
      actionLabel={t('surname.make-a-tree.make-tree-action.label', 'Add your branches')}
      actionName="MakeATree"
    />
  )
}

function GetHelp() {
  const { t } = useTranslation()
  return (
    <DiscoveryItem
      to={`${baseUrl}/help/helpcenter/one-on-one`}
      src={getHelp}
      heading={t('surname.get-help.get-help.heading', 'Get genealogy help')}
      subHeading={t(
        'surname.get-help.get-help.subheading',
        "You're not on your own. We offer guidance and resources—in person and online."
      )}
      actionLabel={t('surname.get-help.get-help-action.label', 'Get help')}
      actionName="GetHelp"
    />
  )
}

function DiscoveryItems() {
  const atWidth = useContainerWidth()

  return atWidth({
    default: (
      <>
        <Row alignX="center" wrap gutters="xs">
          <SearchForAncestors />
        </Row>
        <Row alignX="center" wrap gutters="xs">
          <MakeATree />
        </Row>
        <Row alignX="center" wrap gutters="xs">
          <GetHelp />
        </Row>
      </>
    ),
    xl: (
      <Row alignX="center" gutters="md">
        <SearchForAncestors />
        <div
          style={{
            maxWidth: 343,
            borderInlineStart: '1px solid lightgray',
            borderInlineEnd: '1px solid lightgray',
            height: '100%',
            padding: '0 20px',
          }}
        >
          <MakeATree />
        </div>
        <GetHelp />
      </Row>
    ),
  })
}

export default function DiscoverSurnamesSection() {
  const { t } = useTranslation()
  return (
    <SurnameSection
      name="discover-surnames"
      navId={NAV_IDS.discoverSurnames}
      heading={t('surname.discover-surnames-section.discover-more.heading', 'Discover more on FamilySearch')}
      subHeading={t(
        'surname.discover-surnames-section.discover-more.subheading',
        'We offer free guidance and resources to help you make more family history discoveries.'
      )}
      layoutBandStyle={{ background: colors.gray02, minWidth: 320 }}
      headingSeparatorSize="xl"
    >
      <DiscoveryItems />
      <Separator size="xl" />
      {i18n.language === 'en' && (
        <Row alignX="center">
          <Button
            data-testid="more-resources-button"
            to={`${baseUrl}/${i18n.language}/about/more-genealogy-resources/`}
            linkName="DiscoverSurnamesSection: ExploreMoreResources"
            emphasis="medium"
          >
            {t('surname.discover-surnames-section.explore-more-action.label', 'Explore more helpful resources')}
          </Button>
        </Row>
      )}
    </SurnameSection>
  )
}
