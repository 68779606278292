import React from 'react'
import { useContainerWidth, Skeleton, colors, Separator, Row } from '@fs/zion-ui'
import { css } from '@emotion/core'
import map from '../../images/map.jpeg'

const background = css`
  background-image:
    linear-gradient(
      264.13deg,
      rgba(80, 192, 242, 0) -0.5%,
      rgba(80, 192, 242, 0.6) 21.5%,
      rgba(170, 128, 224, 0.6) 85.66%,
      rgba(170, 128, 224, 0) 105.8%
    ),
    linear-gradient(
      266.99deg,
      rgba(80, 192, 242, 0.8928) 4.71%,
      rgba(26, 92, 177, 0.917737) 20.52%,
      rgba(0, 43, 145, 0.93) 34.53%,
      rgba(75, 0, 111, 0.93) 66.27%,
      rgba(97, 30, 137, 0.921319) 79.23%,
      rgba(170, 128, 224, 0.8928) 99.42%
    ),
    url(${map});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const countryWrapper = css`
  background: ${colors.gray00};
`

export default function SurnameLoading({ showChips }) {
  const atWidth = useContainerWidth()

  return (
    <>
      {showChips && (
        <>
          <div>
            <Skeleton.Paragraph maxWidth="150px" size="sm" />
            <Row>
              <Skeleton.Chip />
              <Skeleton.Chip />
              <Skeleton.Chip />
            </Row>
          </div>
          <Separator size="md" />
        </>
      )}
      <div css={background}>
        <Separator size={atWidth({ default: 'lg', sm: 'xl' })} />
        <Skeleton.H1 centered maxWidth={atWidth({ default: 300, sm: 544 })} />
        <Separator size={atWidth({ default: 'lg', sm: 'sm', md: 'xl' })} />
      </div>
      <div css={countryWrapper}>
        <Separator size={atWidth({ default: 'lg', md: 'xl' })} />
        <Skeleton.H1 centered maxWidth={atWidth({ default: 280, sm: 340 })} />
        <Row alignX="center" gutters="xxl" guttersY="nano" wrap>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
          <div>
            <Separator size="md" />
            <Skeleton.Image centered height={212} width={172} />
            <Separator size="md" />
            <Skeleton.H5 centered maxWidth={300} subheadingLineCount={1} />
            <Separator size="xl" />
          </div>
        </Row>
      </div>
    </>
  )
}
