import { Cell, LayoutBand, Row, colors, useContainerWidth } from '@fs/zion-ui'
import React, { useEffect, useState, useCallback } from 'react'
import { genericHashLink } from 'react-router-hash-link'
import { Link } from '@fs/zion-router'
import { i18n } from '@fs/zion-locale'
import throttle from 'lodash.throttle'
import { css } from '@emotion/core'

const navHeightCss = css`
  height: 110px;
`
const hoverLinkStyle = css`
  &:hover {
    font-weight: bold;
  }
`

const HashLink = genericHashLink(Link)
export const STICKY_NAV_HEIGHT = 110

export const NAV_IDS = {
  surnameSearchMain: 'surname-search-main',
  stickyNav: 'sticky-nav',
  whatIsASurname: 'what-is-surname',
  commonSurnames: 'most-common-surname',
  faqs: 'surname-faqs',
  discoverSurnames: 'discover-surnames',
  browseSurnames: 'browse-surnames',
  createAccount: 'create-account-surname',
}

export function getNavSections() {
  return [
    {
      id: NAV_IDS.whatIsASurname,
      title: i18n.t('surname.what-is-a-surname-section.what-is-a-lastname.heading', 'What is a last name?'),
    },
    {
      id: NAV_IDS.commonSurnames,
      title: i18n.t('surname.sticky-nav-surname-section.most-common-names.label', 'Common names'),
    },

    {
      id: NAV_IDS.faqs,
      title: i18n.t('surname.sticky-nav-surname-section.faqs.label', 'FAQs'),
    },

    {
      id: NAV_IDS.discoverSurnames,
      title: i18n.t('surname.sticky-nav-surname-section.discover-more.label', 'Discover more'),
    },
    {
      id: NAV_IDS.createAccount,
      title: i18n.t('surname.create-account-section.join-familysearch.heading', 'Access FamilySearch'),
    },
  ]
}

function getVisibilityParams(section) {
  const element = document.getElementById(section.id)
  const rect = element?.getBoundingClientRect?.()
  const { top, bottom } = rect || {}
  const windowInnerHeight = window.innerHeight
  const topAboveViewPort = top <= STICKY_NAV_HEIGHT
  const topAtViewPort = top < STICKY_NAV_HEIGHT + 5 && top > STICKY_NAV_HEIGHT - 5
  const bottomInViewPort = bottom > STICKY_NAV_HEIGHT && bottom <= windowInnerHeight
  const bottomBelowViewPort = bottom > windowInnerHeight
  return { topAboveViewPort, topAtViewPort, bottomInViewPort, bottomBelowViewPort }
}

export function isVisibleInViewport(section) {
  const { topAboveViewPort, topAtViewPort, bottomInViewPort, bottomBelowViewPort } = getVisibilityParams(section)
  return (topAboveViewPort || topAtViewPort) && (bottomInViewPort || bottomBelowViewPort)
}

export function scrollWithOffset(el) {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -STICKY_NAV_HEIGHT
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
}

function useStickyNavSurnameSection() {
  const sections = getNavSections({})
  const [activeSection, setActiveSection] = useState(sections.findLast(isVisibleInViewport))
  const NavWrapper = 'div'

  // This effect is needed by react-router-hash-link to scroll to hash on a full page refresh
  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const id = hash.replace('#', '')
      const scrollIntoSection = () => {
        const element = document.getElementById(id)
        if (element) {
          scrollWithOffset(element)
        }
      }

      scrollIntoSection()

      // Timeouts with extra scrolls help slower devices and are harmless to fast ones.
      setTimeout(() => {
        scrollIntoSection()
      }, 1)
      setTimeout(() => {
        scrollIntoSection()
      }, 100)
      setTimeout(() => {
        scrollIntoSection()
      }, 1000)
    }
  }, [])

  const handleScroll = useCallback(() => {
    setActiveSection(sections.findLast(isVisibleInViewport))
  }, [sections, setActiveSection])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnScroll = useCallback(throttle(handleScroll, 100, { leading: true, trailing: true }), [handleScroll])

  useEffect(() => {
    window.addEventListener('scroll', throttledOnScroll)
    return () => {
      window.removeEventListener('scroll', throttledOnScroll)
    }
  }, [throttledOnScroll])

  return { sections, activeSection, NavWrapper }
}

const layoutBandStyle = {
  position: 'sticky',
  top: 0,
  height: STICKY_NAV_HEIGHT,
  borderBottom: `1px solid ${colors.gray10}`,
  background: '#ffffff',
  zIndex: 1,
}

function getHashLinkStyle({ section, activeSection }) {
  return section?.id && activeSection?.id === section.id
    ? { fontWeight: 'bold', color: 'var(--gray100)', textDecoration: 'none', textWrap: 'nowrap' }
    : { color: 'var(--gray100)', textDecoration: 'none', textWrap: 'nowrap' }
}

export default function StickyNavSurnameSection() {
  const atWidth = useContainerWidth()
  const hideStickyNav = atWidth({ default: true, md: false })
  const { sections, activeSection, NavWrapper } = useStickyNavSurnameSection()

  return (
    <LayoutBand
      alignY="top"
      marginX="nano"
      marginY="none"
      id={NAV_IDS.stickyNav}
      data-testid="section-surname-page-nav"
      style={layoutBandStyle}
    >
      <Row as="div" alignX="center" alignY="middle" guttersY="none" guttersX="lg" css={navHeightCss}>
        {!hideStickyNav && (
          <NavWrapper style={{ padding: '42px 0' }}>
            <Row
              as="ul"
              alignX="center"
              alignY="middle"
              guttersY="none"
              guttersX={atWidth({ default: 'xxs', xl: 'sm', xxl: 'lg' })}
              wrap
            >
              {sections.map((section) => {
                return (
                  <Cell as="li" noShrinkX key={section.id} style={{ listStyle: 'none', padding: 0 }}>
                    <HashLink
                      css={hoverLinkStyle}
                      wayfinding
                      noScrollTop
                      smooth
                      style={getHashLinkStyle({ section, activeSection })}
                      to={`/surname/#${section.id}`}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      {section.title}
                    </HashLink>
                  </Cell>
                )
              })}
            </Row>
          </NavWrapper>
        )}
      </Row>
    </LayoutBand>
  )
}
