import { LayoutBand, MarginBox, Row, Separator, colors, H2, Paragraph } from '@fs/zion-ui'
import React from 'react'

const SurnameSectionWrapper = React.forwardRef(function SurnameSectionWrapper(props, ref) {
  const {
    name,
    layoutBandStyle = { background: colors.gray02, minWidth: 320 },
    navId,
    marginX = 'lg',
    marginY = 'xl',
    maxWidth,
    children,
  } = props
  const marginBoxStyle = { scrollMarginTop: 109 }
  const testId = name ? `${name}-section` : undefined
  return (
    <LayoutBand
      as="section"
      id={navId}
      alignY="middle"
      style={layoutBandStyle}
      data-testid={testId}
      maxWidth={maxWidth}
    >
      <MarginBox marginX={marginX} marginY={marginY} ref={ref} style={marginBoxStyle}>
        <Separator size="md" />
        {children}
        <Separator size="lg" />
      </MarginBox>
    </LayoutBand>
  )
})
export { SurnameSectionWrapper }

const SurnameSection = React.forwardRef(function SurnameSection(props, ref) {
  const { heading, subHeading, children, headingSize = 'H3', headingSeparatorSize = 'md', maxWidth = 'xl' } = props
  return (
    <SurnameSectionWrapper {...props} ref={ref}>
      <Row alignX="center" wrap gutters="xl">
        <div style={{ maxWidth }}>
          <H2 centered size={headingSize}>
            {heading}
          </H2>
          <Separator size="xs" />
          <Paragraph centered size="md" secondary>
            {subHeading}
          </Paragraph>
        </div>
      </Row>
      <Separator size={headingSeparatorSize} />
      {children}
    </SurnameSectionWrapper>
  )
})

export default SurnameSection
