import React from 'react'
import { Card, colors, Divider } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { RelationshipSkeleton, EventSkeleton, PersonBlockSkeleton } from './components'

const stretchCss = css`
  margin-top: calc(var(--bleedable-top) * 1);
  margin-bottom: calc(var(--bleedable-bottom) * -1);
  margin-inline-start: calc(var(--bleedable-sides) * -1);
  width: calc(100% + calc(var(--bleedable-sides) * 2));
`
const bottomCss = css`
  background-color: ${colors.gray02};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-height: 240px;
  padding: 0 16px;
  ${stretchCss}
`

export default function ProfileCardSkeleton() {
  return (
    <Card>
      <PersonBlockSkeleton />
      <div css={[stretchCss, { marginTop: 0 }]}>
        <Divider />
      </div>
      <div css={bottomCss}>
        <EventSkeleton />
        <RelationshipSkeleton />
      </div>
    </Card>
  )
}
