import React from 'react'
import { useTranslation } from 'react-i18next'
import { useContainerWidth, FlowGrid } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { AdvancedSearchCard } from '../../components'
import ProfileCard, { ProfileCardSkeleton } from './ProfileCard'

const treeCardSkeletonCss = css`
  height: 100%;
  & * {
    flex-grow: 1;
  }
`

const treeCardCss = css`
  height: 100%;
  & a {
    display: block;
    height: 100%;
    width: 100%;
  }
  & a > div {
    flex-grow: 1;
    height: 100%;
  }
`

// This is where you change how many Tree cards you want in a row at each breakpoint.
const columnCounts = (rows = 1) => {
  return { default: 1 * rows, md: 2 * rows, xl: 3 * rows, xxl: 4 * rows }
}

export const TreeProfileCardsSkeleton = () => {
  const atWidth = useContainerWidth()

  let skeletonKey = 0
  return (
    <FlowGrid columnCount={atWidth(columnCounts())}>
      {[...Array(atWidth(columnCounts()))].map(() => {
        return (
          <div css={treeCardSkeletonCss} key={skeletonKey++}>
            <ProfileCardSkeleton />
          </div>
        )
      })}
    </FlowGrid>
  )
}

// previewRows is the number of rows we want to see for isPreview.
export default function TreeProfileCards({ data, total, isPreview = false, previewRows = 2, getPersonUrl }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()

  const maxNumberOfPreview = atWidth(columnCounts(previewRows))
  const previewDataTree = isPreview ? data?.slice?.(0, maxNumberOfPreview) : data

  return (
    <div data-testid="tree-results">
      <FlowGrid columnCount={atWidth(columnCounts())}>
        {previewDataTree?.map((person, i) => (
          <div data-testid="tree-results-card" css={treeCardCss} key={person?.identifier || i}>
            <ProfileCard person={person} personUrl={getPersonUrl && getPersonUrl(person)} external />
          </div>
        ))}
        {total <= 11 && (
          <AdvancedSearchCard
            hasFewResults
            top={{
              to: './',
              linkText: t('one-search.quick-search.action', 'Quick Search'),
              text: t(
                'one-search.tree.try-diff.text',
                'Try substituting different information in your search or leaving some information out. '
              ),
              linkName: 'Advanced Search:Quick Search',
            }}
            bottom={{
              external: true,
              to: '/search/tree',
              text: t(
                'one-search.or-try-advanced-search.text',
                'Or you can try the advanced search to find more results about ancestors.'
              ),
              linkText: t('one-search.family-tree-search.link', 'Family Tree Search'),
              linkName: 'Advanced Search:Tree',
            }}
          />
        )}
      </FlowGrid>
    </div>
  )
}
