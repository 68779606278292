import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from '@fs/zion-router'
import ZionForm, { FormTextField, useZionForm } from '@fs/zion-form'
import { LayoutBand, Row, Separator, usePageWidth, IconButton, colors } from '@fs/zion-ui'
import { MenuSearch } from '@fs/zion-icon'
import { css } from '@emotion/core'
import { getSurnameResolver } from '../Search/surnameResolver'
import { formatNameParam } from '../../../ResultsPage/utils'

const buttonContainerCss = css`
  opacity: 100;
  background: ${colors.gray00};
  border-radius: 24px;
`

const formFieldContainerCss = css`
  margin: 16px 4px 0 24px;
`

const SurnameSearchAgainSection = () => {
  const [t] = useTranslation()
  const { setQuery } = useQueryParams()
  const atWidth = usePageWidth()
  const isMobile = atWidth({ default: true, md: false })
  const defaultValues = { yourLastName: '' }
  const methods = useZionForm({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: getSurnameResolver(t),
  })

  const {
    formState: { isValid },
  } = methods

  const handleFormSubmit = ({ yourLastName }) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setQuery({ surname: formatNameParam(yourLastName) })
  }

  return (
    <LayoutBand>
      <Separator size="xs" />
      <ZionForm autoComplete="off" methods={methods} onSubmit={handleFormSubmit} style={isMobile ? {} : { width: 455 }}>
        <Row
          alignX="center"
          alignY="middle"
          alignItemsY="middle"
          gutters="nano"
          style={{ '--button-text-color': 'var(--gray90)' }}
        >
          {/* eslint-disable-next-line @fs/zion/logical-over-directional */}
          <div css={[formFieldContainerCss, { width: atWidth({ default: 200, sm: 282, md: 350, xl: 350 }) }]}>
            <FormTextField
              autoComplete="family-name"
              name="yourLastName"
              type="text"
              data-testid="your-last-name-field"
              aria-label={t('surname-app.search.placeholder', 'Enter a different last name')}
              placeholder={t('surname-app.search.placeholder', 'Enter a different last name')}
            />
          </div>
       <div css={buttonContainerCss}>
         <IconButton
            disabled={!isValid}
            Icon={MenuSearch}
            size="md"
            emphasis="high"
            type="submit"
            linkName="SurnameSearchAgainSection: Search"
            data-testid="surnames-search-button"
            title={t('common-ui:search.action', 'Search')}
            color="gray"
          />
       </div>
        </Row>
      </ZionForm>
      <Separator size={isMobile ? 'xl' : 'lg'} />
    </LayoutBand>
  )
}

export default SurnameSearchAgainSection
