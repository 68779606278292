import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from '@fs/zion-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  Paragraph,
  Button,
  colors,
  LayoutBand,
  MarginBox,
  Row,
  Separator,
  useContainerWidth,
  InlineButton,
  FlowGrid,
  Breadcrumbs,
  Paginator,
  Bleed,
  H4,
} from '@fs/zion-ui'
import ZionForm, { FormTextField, useZionForm } from '@fs/zion-form'
import { NoticeLoading } from '@fs/zion-icon'
import { NotFound } from '@fs/zion-error-page'
import { NAV_IDS } from './Search/sections/StickyNavSurnameSection'
import { BrowseSurnameLinks } from './Search/sections/BrowseSurnamesSection'
import useBrowseSurnames from './hooks/useBrowseSurnames'
import { getSurnameResolver } from './Search/surnameResolver'
import { surnameDirectory } from '../../paths'
import { useSurname } from '../../ResultsPage/hooks'

function SurnamesSearchSection() {
  const [t] = useTranslation()
  const history = useHistory()
  const { push } = history
  const [surname, setSurname] = React.useState()

  React.useEffect(() => {
    let isCurrent = true
    if (isCurrent && surname) push(`/surname?surname=${surname}`)

    return () => {
      isCurrent = false
    }
  }, [surname, push])
  const defaultValues = { yourLastName: '' }
  const methods = useZionForm({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: getSurnameResolver(t),
  })

  const {
    formState: { isValid },
  } = methods

  const handleFormSubmit = ({ yourLastName }) => {
    setSurname(yourLastName)
  }

  const { isSuccess } = useSurname(surname)
  return (
    <div>
      <Row alignX="center">
        <ZionForm autoComplete="off" methods={methods} onSubmit={handleFormSubmit}>
          <div style={{ minWidth: 480 }}>
            <Row wrap alignX="center" gutters="xs">
              <FormTextField
                autoComplete="family-name"
                growX
                aria-label={t(
                  'surname-directory.surnames-search-section.want-to-search.label',
                  'Want to search for a different surname?'
                )}
                name="yourLastName"
                type="text"
                data-testid="your-last-name-field"
                placeholder={t('surname.surname-search-section.your-last-name.placeholder', 'Enter a last name')}
                label={t(
                  'surname-directory.surnames-search-section.want-to-search.label',
                  'Want to search for a different surname?'
                )}
              />
              <Button
                alignY="nextToInput"
                disabled={!isValid}
                Icon={!isSuccess && surname && NoticeLoading}
                emphasis="high"
                linkName="SurnameDirectory: Search"
                type="submit"
                data-testid="surnames-search-button"
              >
                {t('surname-directory.surnames-search-section.search-action.label', 'Search')}
              </Button>
            </Row>
          </div>
        </ZionForm>
      </Row>
      <Separator size="md" />
    </div>
  )
}

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

function PopularSurnamesStartingWithLetter({ letter, topSurnames, exact2, exact3 }) {
  const { t } = useTranslation()
  const atSize = useContainerWidth()
  if (!letter || !topSurnames?.length || exact2 || exact3) return null

  return (
    <Row alignX="center" wrap gutters="xs">
      <MarginBox
        marginX="md"
        marginY="sm"
        style={{ background: colors.gray00, width: atSize({ default: 320, xl: 994 }) }}
      >
        <Paragraph>
          {t(
            'surname-directory.popular-surnames-starting-with-letter.most-popular-surnames.text',
            `These are the most popular surnames beginning with the letter {letter}. Click on the names below to learn more about their meaning, history and origins.`,
            { letter: letter?.toUpperCase?.() }
          )}
        </Paragraph>

        <Row alignX="center">
          <div>
            {topSurnames?.map((surname, index) => {
              const name = surname?.name ? capitalizeFirstLetter(surname?.name) : index
              const to = `/surname?surname=${name}`
              return (
                <Row key={`${name}`} alignX="start">
                  <Link to={to} wayfinding>
                    {`${index + 1}. ${name}`}
                  </Link>
                </Row>
              )
            })}
          </div>
        </Row>
      </MarginBox>
    </Row>
  )
}

const PAGE_SIZE = 100

function paginate(array, pageNumber, pagesize = PAGE_SIZE) {
  return array.slice(pageNumber * pagesize, (pageNumber + 1) * pagesize)
}

function SurnamesInGrid({ names }) {
  const [index, setIndex] = useState(0)
  const length = names?.length

  useEffect(() => {
    setIndex(0)
  }, [length])

  const atWidth = useContainerWidth()
  return (
    <>
      <MarginBox marginX="xl" marginY="sm">
        <Row alignX="center" wrap>
          <FlowGrid centered columnCount={atWidth({ default: 2, xs: 3, sm: 4 })} orientation="vertical" gutters="nano">
            {paginate(names, index)?.map((surname) => {
              const name = surname?.name ? capitalizeFirstLetter(surname?.name) : index
              const to = `/surname?surname=${name}`
              return (
                <Link key={`${name}-${index}`} to={to} wayfinding>
                  {name}
                </Link>
              )
            })}
          </FlowGrid>
        </Row>
      </MarginBox>
      <Bleed sides bottom style={{ background: colors.gray02 }}>
        {length > PAGE_SIZE && (
          <Paginator hideResultsPerPage index={index} setIndex={setIndex} pageCount={PAGE_SIZE} length={length} />
        )}
      </Bleed>
    </>
  )
}

function LastNameBreadCrumbs({ surnamePrefix, exact2, exact3 }) {
  const [t] = useTranslation()
  if (!surnamePrefix) return []
  const lastNameCrumb = {
    text: t('surname-directory.last-name-bread-crumbs.last-names.label', 'Last names'),
    to: `/surname#${NAV_IDS.browseSurnames}`,
  }
  const namePrefixChars = surnamePrefix.split('')
  let breadCrumbs = namePrefixChars.reduce(
    (crumbs, letter, index) => {
      if (index === 0) {
        const text = letter.toUpperCase()
        return [...crumbs, { text, to: `${surnameDirectory}/${letter}` }]
      }
      const prefix = surnamePrefix.slice(0, index + 1)
      const text = capitalizeFirstLetter(prefix)
      return [...crumbs, { text, to: `${surnameDirectory}/${prefix}` }]
    },
    [lastNameCrumb]
  )
  if (exact2) {
    breadCrumbs.push({
      text: t(
        'surname-directory.last-name-bread-crumbs.two-lettered-exact.text',
        `Two-lettered surnames beginning with "{surnamePrefix}"`,
        {
          surnamePrefix: breadCrumbs[breadCrumbs.length - 1].text,
        }
      ),
    })
  } else if (exact3) {
    breadCrumbs.push({
      text: t(
        'surname-directory.last-name-bread-crumbs.three-lettered-exact.text',
        `Three-lettered surnames beginning with "{surnamePrefix}"`,
        {
          surnamePrefix: breadCrumbs[breadCrumbs.length - 1].text,
        }
      ),
    })
  }

  // Add react keys to breadcrumbs
  breadCrumbs = breadCrumbs.map((b) => ({ ...b, key: b.text }))

  return (
    <Row gutters="nano">
      <Breadcrumbs breadcrumbs={breadCrumbs} />
    </Row>
  )
}

function ExactBeginningWithHeader({ namePrefix, exactSurnames }) {
  if (namePrefix?.length === 2) {
    return (
      <>
        Three-letter surnames beginning with{' '}
        <InlineButton
          to={`3letter-${namePrefix.toLowerCase()}`}
        >{`${namePrefix} (${exactSurnames?.length})`}</InlineButton>
      </>
    )
  }
  if (namePrefix.length === 1) {
    return (
      <>
        Two-letter surnames beginning with{' '}
        <InlineButton
          to={`2letter-${namePrefix.toLowerCase()}`}
        >{`${namePrefix} (${exactSurnames?.length})`}</InlineButton>
      </>
    )
  }
  return null
}

function BrowseMoreSurnameStartingWith({ surnamePrefix, nextIndexLayer, exactSurnames, allSurnames, exact2, exact3 }) {
  const { t } = useTranslation()
  const pascalCasedNamePrefix = capitalizeFirstLetter(surnamePrefix)
  const atSize = useContainerWidth()

  return (
    <Row alignX="center" wrap gutters="xs">
      <MarginBox
        marginX="md"
        marginY="sm"
        style={{ background: colors.gray00, width: atSize({ default: 320, xl: 994 }) }}
      >
        <H4 size="H6">
          {t(
            'surname-directory.browse-more-surnames-starting-with.browse-more-surnames.heading',
            'Browse for more surnames starting with'
          )}
        </H4>
        <Separator size="sm" />
        <LastNameBreadCrumbs surnamePrefix={surnamePrefix} exact2={exact2} exact3={exact3} />
        {!exact2 && !exact3 && (exactSurnames?.length || nextIndexLayer?.length) && (
          <>
            <BrowseSurnameLinks surnamePrefixes={nextIndexLayer} alignX="start" />
            {exactSurnames?.length > 0 && (
              <H4 size="H6">
                <ExactBeginningWithHeader namePrefix={pascalCasedNamePrefix} exactSurnames={exactSurnames} />
              </H4>
            )}
          </>
        )}
        {(exact2 || exact3) && exactSurnames?.length > 0 && <SurnamesInGrid names={exactSurnames} />}
        {surnamePrefix.length === 4 && allSurnames?.length > 0 && <SurnamesInGrid names={allSurnames} />}
      </MarginBox>
    </Row>
  )
}

function LastNamesStartingWithPage({ surnamesData, surnamePrefix, exact2, exact3 }) {
  const { t } = useTranslation()
  return (
    <LayoutBand alignY="top" maxWidth="xl" data-testid="section-surnamesearch" style={{ background: colors.gray02 }}>
      <MarginBox marginX="nano" marginY="md">
        <SurnamesSearchSection />
        <Row alignX="center" wrap gutters="xs">
          <H4 growX centered size="H3">
            {t(
              'surname-directory.last-names-starting-with-page.lastnames-starting-with.text',
              `Last names starting with {prefix}`,
              {
                prefix: capitalizeFirstLetter(surnamePrefix),
              }
            )}
          </H4>
        </Row>
        <Separator />
        <PopularSurnamesStartingWithLetter
          letter={surnamePrefix}
          topSurnames={surnamesData?.topSurnames}
          exact2={exact2}
          exact3={exact3}
        />
        <Separator />

        <BrowseMoreSurnameStartingWith
          surnamePrefix={surnamePrefix}
          nextIndexLayer={surnamesData?.nextIndexLayer}
          exactSurnames={surnamesData?.exactSurnames}
          allSurnames={surnamesData?.allSurnames}
          exact2={exact2}
          exact3={exact3}
        />
      </MarginBox>
    </LayoutBand>
  )
}

function InternalSurnameDirectory() {
  let { name } = useParams()
  name = name?.trim() || ''
  const nameSplit = name?.split('-')
  const exact2 = nameSplit?.[0] === '2letter'
  const exact3 = nameSplit?.[0] === '3letter'
  const surnamePrefix = exact2 || exact3 ? nameSplit[1] : name
  const { surnamesData = {} } = useBrowseSurnames(surnamePrefix)

  if (surnamesData.error || !surnamePrefix || surnamePrefix.length > 4) return <NotFound />
  if (surnamesData.isFetching) return null

  // if (!surnamePrefix || !surnamePrefix.length || surnamePrefix.length > 4) return <LastNameNotFoundPage />

  return (
    <LastNamesStartingWithPage
      surnamesData={surnamesData?.data}
      surnamePrefix={surnamePrefix}
      exact2={exact2}
      exact3={exact3}
    />
  )
}

export default function SurnameDirectory() {
  const queryClientRef = React.useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          staleTime: 1000 * 60 * 1000,
          cacheTime: 1000 * 60 * 1000,
        },
      },
    })
  )

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <InternalSurnameDirectory />
    </QueryClientProvider>
  )
}
