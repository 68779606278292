import React from 'react'
import { stringifyQuery } from '@fs/zion-router'
import {
  Cell,
  Grid,
  LayoutBand,
  Separator,
  useContainerWidth,
  usePageWidth,
  DataPreview,
  H2,
  Paragraph,
  colors,
  Image,
  Row,
} from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { Observable } from '@fs/zion-frontend-friends'
import sideImage from './assets/discoverySearchSideImage.svg'
import SearchForm from './SearchForm/SearchForm'
import RecordsLoading from '../RecordsLoading'
import { formatName } from '../../../ResultsPage/utils'

const DiscoverySearchSection = ({ historicalRecordsCount, state, surname }) => {
  historicalRecordsCount = historicalRecordsCount?.toLocaleString?.() || 0
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const isMobile = usePageWidth()({ default: true, xl: false })
  const textHeading =
    historicalRecordsCount === '0'
      ? 'Discover Historical Records for the {surname} Family'
      : 'We found {historicalRecordsCount} records for the {surname} last name'
  const idHeading =
    historicalRecordsCount === '0'
      ? 'surname.results.discovery-search.header-two'
      : 'surname.results.discovery-search.header'
  const { nameDisplay, nameParam } = formatName(surname)
  const layoutBandProps = { marginX: 'xl', color: colors.green03 }

  return (
    <LayoutBand data-testid="section-discoverysearch" {...layoutBandProps}>
      <Separator size="xxl" />
      <Observable rootMargin="300px">
        <DataPreview.Loading loading={state.isLoading}>
          <RecordsLoading />
        </DataPreview.Loading>
        <DataPreview.Ready loading={state.isLoading}>
          <Grid>
            <Cell columns={atWidth({ default: 12, xl: 7 })}>
              <H2 size="H3">
                {t(idHeading, textHeading, {
                  historicalRecordsCount,
                  surname: nameDisplay,
                })}
              </H2>

              <Separator size={isMobile ? 'sm' : 'nano'} />
              <Paragraph size="md" data-testid="discovery-subheader">
                {t(
                  'surname.results.discovery-search.subheader',
                  'Explore historical collections, such as birth records, death certificates, immigration data, and more about the {surname} family.',
                  { surname: nameDisplay }
                )}
              </Paragraph>
              <Separator size={isMobile ? 'lg' : 'md'} />
              <SearchForm
                searchValues={{ 'q.surname': nameParam }}
                updateSearchValues={({ searchValues }) => {
                  window.location.href = `/search/discovery/results/?${stringifyQuery(searchValues)}`
                }}
              />
            </Cell>
            <Cell columns={isMobile ? 12 : 5}>
              <Row alignX="center" alignY="middle">
                <Image src={sideImage} alt="Discovery Search Side Image" noCrop />
              </Row>
            </Cell>
          </Grid>
        </DataPreview.Ready>
      </Observable>
      <Separator size="xxl" />
    </LayoutBand>
  )
}

export default DiscoverySearchSection
