import React, { Suspense } from 'react'
import { i18n } from '@fs/zion-locale'
import { useContainerWidth } from '@fs/zion-ui'
import { usePageView } from '@fs/zion-analytics'
import CommonSurnamesSection from './sections/CommonSurnamesSection'
import FAQSurnamesSection from './sections/FAQSurnamesSection'
import DiscoverSurnamesSection from './sections/DiscoverSurnamesSection'
import SurnameSearchPageHead from './SurnameSearchPageHead'
import SurnameSearchSection from './sections/SurnameSearchSection'
import WhatIsASurnameCardSection from './sections/WhatIsASurnameCardSection'
import AccessAccountSection from './sections/AccessAccountSection'
import SurnameSearchPageSkeleton from './SurnameSearchPageSkeleton'
import SurnameNavToTopButton from './SurnameNavToTopButton'

const StickyNavSurnameSection = React.lazy(() => import('./sections/StickyNavSurnameSection'))
const BrowseSurnamesSection = React.lazy(() => import('./sections/BrowseSurnamesSection'))
const BROWSEABLE_LOCALES = [
  'id',
  'ms',
  'ceb',
  'cs',
  'da',
  'de',
  'et',
  'en',
  'es',
  'to',
  'fr',
  'sm',
  'hr',
  'it',
  'ht',
  'lv',
  'lt',
  'hu',
  'mg',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'sq',
  'sk',
  'fi',
  'sv',
  'tl',
  'vi',
  'tr',
  'fj',
]

function localeIsBrowsable(locale) {
  return BROWSEABLE_LOCALES.indexOf(locale) !== -1
}

export default function SurnameSearchPage() {
  const atSize = useContainerWidth()

  usePageView({ page_detail: 'Surname SEO:Search' })
  return (
    <Suspense fallback={<SurnameSearchPageSkeleton />}>
      <SurnameSearchPageHead />
      <SurnameSearchSection />

      {/* <--- Page Fold  ---> */}
      <Suspense fallback={null}>{atSize({ default: null, lg: <StickyNavSurnameSection /> })}</Suspense>
      <WhatIsASurnameCardSection />
      <CommonSurnamesSection />
      <FAQSurnamesSection />
      <DiscoverSurnamesSection />
      {localeIsBrowsable(i18n.language) && <BrowseSurnamesSection />}
      <AccessAccountSection linkNamePrefix="SurnameSearch:AccessAccount" />
      <SurnameNavToTopButton />
    </Suspense>
  )
}
