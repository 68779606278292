import React from 'react'
import { useTranslation } from 'react-i18next'
import { NoMemories } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function MemoriesError({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoMemories,
    heading: t('one-search.memories.no-memories.error', 'Memories data is unavailable.'),
    subheading: t('one-search.data.error', 'You may need to wait a few minutes and try again.'),
    testid: 'memories-error',
  }

  return small ? <SmallNoResults {...data} /> : <BigNoResults {...data} />
}
