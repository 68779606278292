import React from 'react'
import { useTranslation } from 'react-i18next'
import { NoTree } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function TreeError({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoTree,
    heading: t('one-search.tree-profiles.no-profiles.error', 'Family Tree data is unavailable.'),
    subheading: t('one-search.data.error', 'You may need to wait a few minutes and try again.'),
    testid: 'tree-error',
  }

  return small ? <SmallNoResults {...data} /> : <BigNoResults {...data} />
}
