import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const getResolver = (t) => {
  const specialCharacters = /^[^!@#$^&%*;()<>]+$/
  const specialCharacterMessage = t('one-search.form.symbols.error', 'Cannot contain symbols.')
  const currentYear = new Date().getFullYear()
  const validYearMessage = t('one-search.form.year-range.error', `Year must be between 1700 and {currentYear}`, {
    currentYear,
  })
  const validLengthMessage = t('one-search.form.maxchar.error', 'Cannot be more than 128 characters.')
  return yupResolver(
    Yup.object().shape({
      givenName: Yup.string().max(128, validLengthMessage).trim().matches(specialCharacters, {
        message: specialCharacterMessage,
        excludeEmptyString: true,
      }),
      surname: Yup.string()
        .max(128, validLengthMessage)
        .trim()
        .required(t('one-search.form.ancestors-last-name.error', 'Last name is required.'))
        .matches(specialCharacters, {
          message: specialCharacterMessage,
          excludeEmptyString: true,
        }),
      relevantYear: Yup.number()
        .min(1700, validYearMessage)
        .max(currentYear, validYearMessage)
        .integer(t('one-search.form.relevant-year.error', 'Year must be a number.'))
        .typeError(t('one-search.form.relevant-year.error', 'Year must be a number.'))
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    })
  )
}

export default getResolver
