import { useQuery } from '@tanstack/react-query'
import axios from '@fs/zion-axios'
import { i18n } from '@fs/zion-locale'

async function mostCommonSurnamesAPI(locale) {
  if (!locale) {
    throw new Error('locale must be defined!')
  }

  const { data } = await axios.get(`/service/homelandhistories/tree-names/top-surnames/tree?lang=${locale}`)
  return data
}

export default function useMostCommonSurnames() {
  const locale = i18n.language
  const commonSurnamesData = useQuery([`common-surnames-${locale}`, locale], () => mostCommonSurnamesAPI(locale), {
    enabled: !!locale,
  })

  return { commonSurnamesData }
}
