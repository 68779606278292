import React from 'react'
import { useQueryParams } from '@fs/zion-router'
import SurnameSearch from './Search'

const SharedSurnamesResults = React.lazy(() => import('./Results'))

const SharedSurnamesExperience = () => {
  const { query } = useQueryParams()
  let { surname1, surname2 } = query
  surname1 = surname1?.trim() || ''
  surname2 = surname2?.trim() || ''

  if (surname1 && surname2) {
    return <SharedSurnamesResults />
  }
  return <SurnameSearch />
}

export default SharedSurnamesExperience
