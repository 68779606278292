import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Paragraph, Ellipsis, Skeleton, H3 } from '@fs/zion-ui'
import { css } from '@emotion/core'

const personContainerCss = css`
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
`
const firstNameCss = css`
  align-self: center;
  max-width: 100%;
  height: 35px;
`
const lastNameCss = css`
  align-self: center;
  max-width: 100%;
`
const avatarContainerCss = css`
  margin-top: 0;
  margin-inline-end: auto;
  margin-bottom: 15px;
  margin-inline-start: auto;
`
const dateCss = css`
  margin: 0 auto;
`
const skeletonContainerCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const PersonBlockSkeleton = () => {
  return (
    <div css={skeletonContainerCss}>
      <div>
        <Skeleton.Avatar size="lg" />
      </div>
      <div css={{ width: 125 }}>
        <Skeleton.H5 />
      </div>
      <div css={{ width: 225 }}>
        <Skeleton.H2 />
      </div>
      <div css={{ width: 150 }}>
        <Skeleton.H5 />
      </div>
      <div css={{ width: 75 }}>
        <Skeleton.H5 />
      </div>
    </div>
  )
}

const chunkName = (fullName = '') => {
  const nameParts = fullName?.trim?.().split?.(' ') || []
  if (nameParts.length === 0) return ['', '']
  if (nameParts.length === 1) return ['', nameParts[0]]
  if (nameParts.length === 2) return [nameParts[0], nameParts[1]]
  if (nameParts.length > 2) {
    const lastName = nameParts.pop()
    return [nameParts.join(' '), lastName]
  }
  return ['', '']
}

export default function PersonBlock({ person }) {
  const [t] = useTranslation()
  const { age } = person
  const ageString = !age ? '' : t('one-search.profile-card.age.text', '(Age {age})', { age })
  const [givenName, surname] = chunkName(person?.name)
  const dateString = `${person?.lifespan} ${ageString}`.trim() || <>&nbsp;</>
  return (
    <div css={personContainerCss} data-testid="person-data-1">
      <div css={avatarContainerCss}>
        <Avatar size="lg" src={person?.portrait} sex={person?.gender?.toLowerCase()} data-testid="card-avatar" />
      </div>
      <div css={firstNameCss}>
        {givenName && (
          <H3 data-testid="card-first-name" size="H5" supplementary>
            <Ellipsis>{givenName}</Ellipsis>
          </H3>
        )}
      </div>
      <div css={lastNameCss}>
        <H3 data-testid="card-last-name" size="H4">
          <Ellipsis>{surname}</Ellipsis>
        </H3>
      </div>
      <div css={dateCss}>
        <Paragraph size="xs">
          <Ellipsis data-testid="card-date">{dateString}</Ellipsis>
        </Paragraph>
      </div>
    </div>
  )
}
