import React from 'react'
import { colors, FlowGrid, Button, Row, Separator, useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { Link } from '@fs/zion-router'
import { baseUrl } from '@fs/zion-config'
import { i18n } from '@fs/zion-locale'
import { NAV_IDS } from './StickyNavSurnameSection'
// eslint-disable-next-line import/no-named-as-default
import useMostCommonSurnames from '../../hooks/useMostCommonSurnames'
import SurnameSection from './SurnameSection'
import { formatName } from '../../../../ResultsPage/utils'

function CommonName({ index, name, count }) {
  const { t } = useTranslation()
  const { nameParam, nameDisplay } = formatName(name)

  return (
    <Link
      to={`/surname?surname=${nameParam}`}
      linkName="CommonSurnamesSection: CommonSurname"
      style={{ textDecoration: 'none', lineHeight: '31px' }}
    >
      <span style={{ color: colors.gray100, fontSize: 18, fontWeight: 600 }}>
        {t('surname.common-name.name.label', '{index, number}. {name} ', { index, name: nameDisplay })}
      </span>
      <span style={{ color: colors.gray100, fontSize: 18, fontWeight: 400 }}>
        {t('surname.common-name.name-count.label', '{count, number}', { count })}
      </span>
    </Link>
  )
}

export default function CommonSurnamesSection() {
  const atWidth = useContainerWidth()
  const { t } = useTranslation()
  const { commonSurnamesData = {} } = useMostCommonSurnames()

  const locale = i18n.language
  if (
    commonSurnamesData.error ||
    commonSurnamesData.isFetching ||
    commonSurnamesData.status !== 'success' ||
    commonSurnamesData.data?.[0]?.error ||
    !commonSurnamesData.data?.[0]?.topNames?.length
  )
    return null

  return (
    <SurnameSection
      name="common-surnames"
      navId={NAV_IDS.commonSurnames}
      heading={t('surname.common-surname-section.most-common-names.heading', '25 most common last names')}
      subHeading={t(
        'surname.common-surname-section.most-common-names.subheading',
        'More than 9 million unique last names are represented in the worldwide family tree. Which are the most common?'
      )}
      layoutBandStyle={locale === 'en' ? { background: colors.green03, minWidth: 320 } : undefined}
    >
      <Row data-testid="common-surnames-list" alignX="center" wrap>
        <FlowGrid
          columnCount={atWidth({ default: 1, md: 2, lg: 3, xl: 4 })}
          orientation="vertical"
          gutters="xxs"
          guttersY="none"
        >
          {commonSurnamesData.data?.[0]?.topNames.map((item, index) => {
            return (
              <CommonName
                key={`${item.name}-${item.treeCount}`}
                index={index + 1}
                name={item.name}
                count={item.treeCount}
              />
            )
          })}
        </FlowGrid>
      </Row>
      <Separator size="xl" />
      <Row alignX="center">
        <Button
          data-testid="add-your-surname-button"
          to={`${baseUrl}/tree/guided/connect-to-tree`}
          linkName="CommonSurnamesSection: AddYourSurname"
          emphasis="medium"
          color="gray"
        >
          {t('surname.common-surname-section.add-surname.action', 'Add your surname to Family Tree')}
        </Button>
      </Row>
    </SurnameSection>
  )
}
