import React from 'react'
import { css } from '@emotion/core'
import { useHistory, stringifyQuery } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import ZionForm, { useZionForm, FormTextField } from '@fs/zion-form'
import { BillboardButton, H1, Paragraph, LayoutBand, MarginBox, Row, Separator, WhiteTheme } from '@fs/zion-ui'
import { getSharedSurnamesResolver } from './sharedSurnameResolver'
import layoutBandBackground from '../../images/mapBig.png'
import worldImage from './assets/world.svg'
import { formatNameParam } from '../../ResultsPage/utils'

const layoutBandCss = css`
  background-image:
    linear-gradient(89.98deg, rgba(96, 4, 40, 0) 0.01%, rgba(252, 78, 109, 0.232) 99.99%),
    linear-gradient(89.95deg, rgba(77, 21, 119, 0.08) 0.04%, rgba(121, 44, 182, 0.8) 100.35%),
    linear-gradient(89.71deg, rgba(1, 182, 209, 0.6) 0.32%, rgba(191, 137, 241, 0.6) 99.81%),
    linear-gradient(300.71deg, rgba(77, 21, 119, 0.5525) 18.63%, rgba(1, 182, 209, 0.85) 81.08%),
    url(${layoutBandBackground});
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
`

const useFormSubmit = (getValues, reset, loadResults) => {
  return () => {
    loadResults(getValues())
    reset()
  }
}

const SurnamesSearchSection = () => {
  const history = useHistory()
  const loadResults = ({ yourLastName, friendsLastName }) => {
    const qs = stringifyQuery({ surname1: formatNameParam(yourLastName), surname2: formatNameParam(friendsLastName) })
    history.push(`/surnames?${qs}`)
  }
  const [t] = useTranslation()

  const defaultValues = { yourLastName: '', friendsLastName: '' }

  const methods = useZionForm({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: getSharedSurnamesResolver(t),
  })

  const {
    formState: { isValid },
    getValues,
    reset,
  } = methods
  const handleFormSubmit = useFormSubmit(getValues, reset, loadResults)

  return (
    <LayoutBand alignY="middle" css={layoutBandCss} maxWidth="nano">
      <Separator size="lg" />
      <Row alignX="center">
        <img src={worldImage} width="103" alt="" />
      </Row>
      <Separator size="md" />
      <WhiteTheme>
        <H1 centered size="H3">
          {t('shared-surnames.search.header', 'Do you share a last name connection with your friends?')}
        </H1>
        <Separator size="sm" />
        <MarginBox marginX="sm">
          <Paragraph centered secondary size="sm">
            {t(
              'shared-surnames.search.subheader',
              'Find out which countries your last name and your friend’s last name are most likely to be found in together.'
            )}
          </Paragraph>
        </MarginBox>
      </WhiteTheme>
      <Separator size="md" />
      <ZionForm methods={methods} onSubmit={handleFormSubmit}>
        <WhiteTheme>
          <FormTextField
            label={t('shared-surnames.search.form.your-last-name.label', 'Your Last Name')}
            name="yourLastName"
            type="text"
            data-testid="your-last-name-field"
          />
          <FormTextField
            label={t('shared-surnames.search.form.friends-last-name.label', "Friend's Last Name")}
            name="friendsLastName"
            type="text"
            data-testid="friend-last-name-field"
          />
        </WhiteTheme>
        <Separator size="sm" />
        <Row alignX="center">
          <BillboardButton disabled={!isValid} emphasis="lightHigh" type="submit" data-testid="surnames-search-button">
            {t('common-ui:find.action', 'Find')}
          </BillboardButton>
        </Row>
      </ZionForm>
      <Separator size="md" />
    </LayoutBand>
  )
}

export default SurnamesSearchSection
