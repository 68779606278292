import React, { useState } from 'react'
import { colors, DataBlock, Image, Paragraph, ResponsiveTableRow, Separator } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { Link } from '@fs/zion-router'
import { css } from '@emotion/core'
import { trackLink } from '@fs/zion-analytics'
import RecordBlock from './RecordBlock'
import placeHolderImage from '../../images/placeHolderImage.jpg'

const titleColor = css`
  color: ${colors.transparent.gray70};
`

const NameCell = ({ name, relationship, collectionName }) => (
  <DataBlock
    data-testid="data-block"
    heading={<span css={titleColor}>{name}</span>}
    data={
      relationship ? (
        <>
          {relationship}
          <br />
          {collectionName}
        </>
      ) : (
        collectionName
      )
    }
  />
)

export default function RecordsTableRow({
  url,
  thumbnailUrl,
  featuredPerson,
  facts,
  relationships,
  originalRecord,
  ...props
}) {
  const [t] = useTranslation()
  const [imageError, setImageError] = useState(false)
  // eslint-disable-next-line react/no-array-index-key
  const factBlocks = facts?.length && facts.map((event, index) => <RecordBlock key={`${url}${index}`} {...event} />)
  let relationBlocks
  if (relationships && Object.keys(relationships).length) {
    let orderedRelationshipTypes = ['fatherNames', 'motherNames', 'spouseNames', 'childrenNames', 'otherNames']
    const relationshipsInResults = Object.keys(relationships)
    orderedRelationshipTypes = orderedRelationshipTypes.filter((relationship) =>
      relationshipsInResults.includes(relationship)
    )
    relationBlocks = orderedRelationshipTypes.map((key, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <RecordBlock key={`${url}${index}`} type={key} info={relationships[key]} />
    ))
  }

  return (
    <ResponsiveTableRow
      data-testid="hr-table-row"
      // onClick adds a hover style, Links are given noLinkStyles. Temporary work around until this is fixed in Zion.
      onClick={() => trackLink({ name: 'Record Row' })}
      column1={
        <Link to={url} external noLinkStyles data-testid="image-column">
          {thumbnailUrl && !imageError ? (
            // GEOLA-467: HTML img element handles onError and alt="" to hide broken image icon.
            <img
              src={thumbnailUrl}
              alt={t('one-search.all.records.alt-image', 'Image record')}
              onError={() => {
                setImageError(true)
              }}
              height="72px"
              width="72px"
            />
          ) : (
            <>
              <Image
                src={placeHolderImage}
                positionY="top"
                alt={t('one-search.no-image.label', 'No image available')}
                maxHeight={72}
                width={72}
              />
              <Separator size="nano" />
            </>
          )}
        </Link>
      }
      column2={
        <Link to={url} external noLinkStyles data-testid="name-column">
          <NameCell {...featuredPerson} />
        </Link>
      }
      column3={
        <Link to={url} external noLinkStyles data-testid="events-column">
          {factBlocks?.length ? (
            factBlocks
          ) : (
            <Paragraph secondary data-testid="record-block-none" size="xs">
              {t('one-search.no-events.label', 'No events listed')}
            </Paragraph>
          )}
        </Link>
      }
      column4={
        <Link to={url} external noLinkStyles data-testid="relationship-column">
          {relationBlocks?.length ? (
            relationBlocks
          ) : (
            <Paragraph secondary data-testid="record-block-none" size="xs">
              {t('one-search.no-relationships.label', 'No relationships listed')}
            </Paragraph>
          )}
        </Link>
      }
      {...props}
    />
  )
}
