import React from 'react'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next/icu.macro'
import {
  Paragraph,
  FlowGrid,
  LayoutBand,
  Separator,
  useContainerWidth,
  usePageWidth,
  H2,
  H3,
  MarginBox,
  colors,
} from '@fs/zion-ui'
import Country from '../../Country'
import { formatName } from '../../../ResultsPage/utils'

const countryColors = [colors.green50, colors.green30, colors.green10]

/**
 * @param {{surname: string, t: any}} props
 */
const CountriesHeader = ({ surname, t }) => {
  return (
    <LayoutBand maxWidth="xs">
      <Separator size="xxl" />
      <H2 centered size="H3">
        {t('surname.results.countries.heading', '{surname} is most likely found in:', {
          surname,
        })}
      </H2>
      <Separator size="xl" />
    </LayoutBand>
  )
}

/**
 *
 * @param {{countries: Array<any>}} props
 */
const CountriesPictures = ({ countries }) => {
  const atWidth = useContainerWidth()
  const isMobile = usePageWidth()({ default: true, lg: false })
  const numberOfCountries = countries?.length
  return (
    <FlowGrid columnCount={atWidth({ default: 1, lg: numberOfCountries || 1 })} gutters={isMobile ? 'xs' : 'pico'}>
      {countries.map(({ name, code, count }, index) => (
        <MarginBox key={`${code}${count}`} data-testid="last-name-country" marginX="md">
          <Country
            countryCode={code}
            countryColor={countryColors[index]}
            height={atWidth({ default: '190px', lg: '180px', xl: '220px' })}
            name={name}
            width={atWidth({ default: 'auto', lg: '180px', xl: '220px' })}
          />
          <Separator size={isMobile ? 'xs' : 'lg'} />
          <H3 centered size="H5">
            {name}
          </H3>
          <Separator size="nano" />
          <Paragraph centered size="sm">
            <Trans
              components={[]}
              defaults="{localCount, plural, one {{localCount} person in Family Tree} other {{localCount} people in Family Tree}}"
              i18nKey="surname.results.number-found.label"
              values={{
                localCount: count?.toLocaleString(),
              }}
            />
          </Paragraph>
          <Separator size={isMobile ? 'md' : 'hide'} />
        </MarginBox>
      ))}
      <Separator size="xxl" />
    </FlowGrid>
  )
}

/**
 * @param {{surname: string, countries: Array<any>}} props
 */
const CountriesSection = ({ surname, countries }) => {
  const [t] = useTranslation()
  const { nameDisplay } = formatName(surname)

  return (
    countries?.length > 0 && (
      <LayoutBand maxWidth="sm" data-testid="section-countries">
        <CountriesHeader surname={nameDisplay} t={t} />
        <CountriesPictures countries={countries} />
      </LayoutBand>
    )
  )
}

export default CountriesSection
