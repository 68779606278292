import React, { useState } from 'react'
import { baseUrl } from '@fs/zion-config'
import { css } from '@emotion/core'
import { LabelTooltip, Paragraph, Row, Separator } from '@fs/zion-ui'
import { trackEvent } from '@fs/zion-analytics'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Apple from './assets/authProviderButtons/Apple.svg'
import AppleDark from './assets/authProviderButtons/AppleDark.svg'
import Google from './assets/authProviderButtons/Google.svg'
import GoogleDark from './assets/authProviderButtons/GoogleDark.svg'
import Facebook from './assets/authProviderButtons/Facebook.svg'
import FacebookDark from './assets/authProviderButtons/FacebookDark.svg'
import ChurchAccount from './assets/authProviderButtons/ChurchAccount.svg'
import ChurchAccountDark from './assets/authProviderButtons/ChurchAccountDark.svg'

const transparentButtonStyle = css`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`

const createProviderUrl = (authProvider, url = `${baseUrl}/tree/guided/connect-to-tree`) =>
  `${baseUrl}/identity/register-api/oauth/${authProvider}/login?returnUrl=${encodeURIComponent(url)}`

const CoreAuthProviderButton = ({ lightImageSrc, darkImageSrc, altText, url, linkName, dense }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    trackEvent({ link_name: linkName, event_name: 'click_action' })
    setTimeout(() => {
      window.location.href = url
    }, 100)
  }

  const buttonSize = dense ? { width: 60, height: 60 } : { width: 73.43, height: 73.43 }
  const imageSize = dense ? { width: 60, height: 60 } : { width: 73.43, height: 73.43 }

  return (
    <button
      type="button"
      css={transparentButtonStyle}
      data-testid="get-started-button"
      data-link-name={linkName}
      aria-label={altText}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={buttonSize}
    >
      <img src={isHovered ? darkImageSrc : lightImageSrc} alt={altText} style={imageSize} />
    </button>
  )
}

const AuthProviderButton = ({ lightImageSrc, darkImageSrc, altText, url, linkName, dense }) =>
  isMobile ? (
    <CoreAuthProviderButton
      lightImageSrc={lightImageSrc}
      darkImageSrc={darkImageSrc}
      altText={altText}
      url={url}
      linkName={linkName}
      dense={dense}
    />
  ) : (
    <LabelTooltip
      morePadding
      target={
        <CoreAuthProviderButton
          lightImageSrc={lightImageSrc}
          darkImageSrc={darkImageSrc}
          altText={altText}
          url={url}
          linkName={linkName}
          dense={dense}
        />
      }
      label={altText}
    />
  )

const AuthProviderButtons = ({ dense, returnUrl, linkNamePrefix }) => {
  const { t } = useTranslation()
  const gutters = dense ? 'xxs' : 'sm'
  return (
    <fieldset>
      <Paragraph as="div" centered>
        <legend style={{ fontWeight: 'bold', fontSize: 16 }}>
          {t('surname.auth-provider-buttons.sign-in-with.label', 'Sign in with:')}
        </legend>
      </Paragraph>
      <Separator size="xs" />
      <Row alignX="center" gutters={gutters} wrap>
        <Row alignX="end" gutters={gutters}>
          <AuthProviderButton
            growX
            lightImageSrc={Google}
            darkImageSrc={GoogleDark}
            altText="Google"
            url={createProviderUrl('google', returnUrl)}
            linkName={`${linkNamePrefix}:googleAuthProviderClicked`}
            dense={dense}
          />
          <AuthProviderButton
            growX
            lightImageSrc={Apple}
            darkImageSrc={AppleDark}
            altText="Apple"
            url={createProviderUrl('apple', returnUrl)}
            linkName={`${linkNamePrefix}:appleAuthProviderClicked`}
            dense={dense}
          />
        </Row>
        <Row alignX="end" wrap gutters={gutters}>
          <AuthProviderButton
            growX
            lightImageSrc={Facebook}
            darkImageSrc={FacebookDark}
            altText="Facebook"
            url={createProviderUrl('facebook', returnUrl)}
            linkName={`${linkNamePrefix}:facebookAuthProviderClicked`}
            dense={dense}
          />
          <AuthProviderButton
            growX
            lightImageSrc={ChurchAccount}
            darkImageSrc={ChurchAccountDark}
            altText={t('surname.auth-provider-buttons.church-account.label', 'Church Account')}
            url={createProviderUrl('churchaccount', returnUrl)}
            linkName={`${linkNamePrefix}:churchAccountProviderClicked`}
            dense={dense}
          />
        </Row>
      </Row>
    </fieldset>
  )
}

export default AuthProviderButtons
