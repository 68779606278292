import React from 'react'
import { FlowGrid, colors, Separator, useContainerWidth, H2, H3, Subheading } from '@fs/zion-ui'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next/icu.macro'
import Country from '../../components/Country'
import { formatNameDisplay } from '../utils'

const countryWrapperCss = css`
  background: ${colors.gray00};
`

const countryColors = ['rgb(151, 130, 209)', 'rgb(110, 129, 202)', '#64AAE2']

export default function SurnameCountries({ surname, countries = [] }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const trimmedCountries = countries.slice(0, 3)
  const numberOfCountries = trimmedCountries?.length

  return (
    <div css={countryWrapperCss} data-testid="surname-countries">
      <Separator size={atWidth({ default: 'lg', md: 'xl' })} />
      <H2 size="H4" centered>
        {t('one-search.surname.countries.label', '{surname} is most likely found in:', {
          surname: formatNameDisplay(surname),
        })}
      </H2>
      {countries && (
        <>
          <Separator size={atWidth({ default: 'xxs', xl: 'xl' })} />
          <FlowGrid columnCount={atWidth({ default: 1, lg: numberOfCountries || 1 })} guttersY="nano">
            {trimmedCountries.map(({ name, code, count }, index) => (
              <div key={`${code}${count}`} data-testid="surname-countries-country">
                <Separator size="md" />
                <Country
                  countryCode={code}
                  countryColor={countryColors[index]}
                  height={atWidth({ default: '172px', md: '212px' })}
                  width={atWidth({ default: '170px', md: '210px' })}
                />
                <Separator size="md" />
                <H3 centered size="H5" supplementary>
                  {name}
                  <Subheading>
                    <Trans
                      components={[]}
                      defaults="{number, plural, one {{number} person in Family Tree} other {{number} people in Family Tree}}"
                      i18nKey="one-search.surname.number-in-tree.caption"
                      values={{
                        number: count.toLocaleString(),
                      }}
                    />
                  </Subheading>
                </H3>
                <Separator size="xl" />
              </div>
            ))}
          </FlowGrid>
        </>
      )}
    </div>
  )
}
