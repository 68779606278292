import React from 'react'
import { Skeleton } from '@fs/zion-ui'
import { css } from '@emotion/core'
import MemoryTile from '@fs/zion-memory-tile'

const containerCss = css`
  min-height: 65px;
`
const memoriesCss = css`
  margin-top: auto;
  margin-inline-end: 0;
  margin-bottom: 0;
  margin-inline-start: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  ${containerCss}

  > div {
    min-width: initial;
  }
`

export const MemoriesSkeleton = () => {
  return (
    <div css={memoriesCss}>
      <Skeleton.Image height="64px" width="64px" />
      <Skeleton.Image height="64px" width="64px" />
      <Skeleton.Image height="64px" width="64px" />
      <Skeleton.Image height="64px" width="64px" />
    </div>
  )
}

export default function Memories({ memories = [], total = 0 }) {
  if (memories?.length) {
    const viewAllMemories = total > 4
    const displayedMemories = viewAllMemories ? memories?.slice(0, 3) : memories?.slice(0, 4)
    const viewAllMemory = memories?.[3]
    return (
      <div css={memoriesCss}>
        {displayedMemories?.map((memory) => (
          <MemoryTile
            key={memory.id}
            type={memory?.contentCategory?.toLowerCase?.()}
            image={memory?.thumbSquareUrl}
            data-testid="memory-tile"
          />
        ))}
        {viewAllMemories && (
          <MemoryTile
            type={viewAllMemory?.contentCategory?.toLowerCase?.()}
            image={viewAllMemory?.thumbSquareUrl}
            viewAllCount={total}
            data-testid="memory-tile-view-all"
          />
        )}
      </div>
    )
  }

  return null
}
