import { useContainerWidth } from '@fs/zion-ui'
import { getPlace, getRelative } from '../helpers'

export const useBottomSection = (person) => {
  const isDesktop = useContainerWidth()({
    default: false,
    sm: true,
  })
  // should we show the bottom section?
  return isDesktop || getPlace(person) || getRelative(person) || person?.artifacts?.total > 0
}

export default null
