import React from 'react'
import Head, { Title } from '@fs/zion-head'
import PropTypes from 'prop-types'
import { getFacebookId } from './helpers'

export default function PageHead({ title, meta, omitSiteName = false, children }) {
  const { noIndex = false, description, canonicalUrl, ogDescription, title: metaTitle, ogTitle, ogImage } = meta || {}

  return (
    <>
      <Title omitSiteName={omitSiteName}>{title}</Title>
      <Head includeHreflang>
        {noIndex && <meta name="robots" content="noindex" />}
        <meta name="description" content={description} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta property="og:title" content={ogTitle || metaTitle} />
        <meta property="og:description" content={ogDescription || description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:secure_url" content={ogImage} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={getFacebookId()} />
        <meta name="twitter:card" content="summary_large_image" />
        {children}
      </Head>
    </>
  )
}
PageHead.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    ogImage: PropTypes.string,
  }),
}
