import { genericHashLink } from 'react-router-hash-link'
import { IconButton } from '@fs/zion-ui'
import { Arrow } from '@fs/zion-icon'
import React, { useEffect, useState } from 'react'
import { useHistory } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { NAV_IDS, scrollWithOffset } from './sections/StickyNavSurnameSection'

const TopButton = genericHashLink(IconButton)

const TopArrow = () => <Arrow size="md" direction="up" />

export default function SurnameNavToTopButton() {
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const { t } = useTranslation()

  return isVisible ? (
    <span style={{ position: 'fixed', zIndex: 1, bottom: 80, insetInlineEnd: 40 }}>
      <TopButton
        Icon={TopArrow}
        color="gray"
        data-testid="goto-top-button"
        emphasis="high"
        linkName="SurnameNavToTopButton: Click"
        onClick={() => {
          const el = document.getElementById(NAV_IDS.surnameSearchMain)
          scrollWithOffset(el)
          history.push(`#${NAV_IDS.surnameSearchMain}`)
        }}
        size="lg"
        smooth
        title={t('surname.nav-to-top-button.label', 'Top')}
      />
    </span>
  ) : null
}
