import { useQuery } from '@tanstack/react-query'
import axios from '@fs/zion-axios'

async function browseSurnamesAPI(surnamePrefix) {
  if (!surnamePrefix) {
    throw new Error('surnamePrefix must be defined!')
  }

  const { data } = await axios.get(`/service/homelandhistories/tree-names/tree-surname-index/${surnamePrefix}`)
  return data
}

export default function useBrowseSurnames(surnamePrefix) {
  const lowerCaseSurnamePrefix =
    surnamePrefix?.length > 0 && surnamePrefix?.length < 5 ? surnamePrefix?.toLowerCase?.() : null

  const surnamesData = useQuery(
    ['browse-surnames', lowerCaseSurnamePrefix],
    () => browseSurnamesAPI(lowerCaseSurnamePrefix),
    {
      enabled: !!lowerCaseSurnamePrefix,
    }
  )

  return { surnamesData }
}
