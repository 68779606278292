import React from 'react'
import { useContainerWidth, Skeleton, Separator, LayoutBand, Grid, Cell, FlowGrid } from '@fs/zion-ui'
import { css } from '@emotion/core'
import backgroundMobile from './Results/assets/discoverySearchBackgroundMobile.jpg'
import backgroundTablet from './Results/assets/discoverySearchBackgroundTablet.jpg'
import backgroundDesktop from './Results/assets/discoverySearchBackground.jpg'

const searchSectionMobileCss = css`
  background-image: url(${backgroundMobile});
  background-position: 0 80%;
  background-size: cover;
`

const searchSectionTabletCss = css`
  background-image: url(${backgroundTablet});
  background-position: center bottom;
  background-size: cover;
`

const searchSectionCss = css`
  align-items: center;
  background-image: url(${backgroundDesktop});
  background-size: cover;
  display: flex;
  justify-content: center;
`

export default function RecordsLoading() {
  const atWidth = useContainerWidth()

  return (
    <div>
      <LayoutBand
        css={atWidth({ default: searchSectionMobileCss, lg: searchSectionTabletCss, xl: searchSectionCss })}
        minHeight={atWidth({ default: 800, lg: 675, xl: 484 })}
      >
        <Separator size={atWidth({ default: 'lg', sm: 'xl' })} />
        <Grid>
          <Cell columns={atWidth({ default: 12, xl: 7 })}>
            <Skeleton.H1 maxWidth={atWidth({ default: 480, sm: 640 })} />
            <Skeleton.H5 maxWidth={atWidth({ default: 480, sm: 640 })} subheadingLineCount={1} />
            <Separator size="md" />
            <FlowGrid columnCount={atWidth({ default: 1, lg: 2 })}>
              <div>
                <Skeleton.Paragraph maxWidth={atWidth({ default: '95px', sm: '110px' })} size="xs" />
                <Skeleton.Button minWidth={atWidth({ default: 340, sm: 240 })} />
              </div>
              <div>
                <Skeleton.Paragraph maxWidth={atWidth({ default: '95px', sm: '110px' })} size="xs" />
                <Skeleton.Button minWidth={atWidth({ default: 340, sm: 240 })} />
              </div>
              <div>
                <Skeleton.Paragraph maxWidth={atWidth({ default: '95px', sm: '110px' })} size="xs" />
                <Skeleton.Button minWidth={atWidth({ default: 340, sm: 240 })} />
              </div>
              <div>
                <Skeleton.Paragraph maxWidth={atWidth({ default: '95px', sm: '110px' })} size="xs" />
                <Skeleton.Button minWidth={atWidth({ default: 340, sm: 240 })} />
              </div>
            </FlowGrid>
          </Cell>
          <Separator size="xs" />
          <Skeleton.Button minWidth={120} />
          <Separator size="md" />
        </Grid>
      </LayoutBand>
    </div>
  )
}
