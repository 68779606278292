import { useQueryParams } from '@fs/zion-router'
import { useQuery } from '@tanstack/react-query'
import { useUser } from '@fs/zion-user'
import axios from '@fs/zion-axios'
import { useCallback } from 'react'
import { getAnonSession } from '@fs/zion-session'
import { getOSOHeader, getQueryString } from '../helpers'
import { formatRecord, formatTreeResult } from './utils'

const localStateParams = ['tab', 'singleTab', 'returnUrl']
const facetPrefixes = ['f.', 'c.', 'm.']
const searchPrefixes = ['q.']
const paginationParams = ['page', 'results']
const temporarySupportedParams = {
  // 2024 Delete this support
  birthDate: 'q.birthLikeDate.from',
  birthPlace: 'q.anyPlace',
  surname: 'q.surname',
  'surname.1': 'q.surname.1',
  'surname.2': 'q.surname.2',
  givenName: 'q.givenName',
  'givenName.1': 'q.givenName.1',
  'givenName.2': 'q.givenName.2',
}

const injectDisplayCountsForFacets = (facets = []) => {
  facets?.forEach?.((facet) => {
    if (facet?.count >= 0 && Number.isInteger(facet.count)) facet.displayCount = facet.count?.toLocaleString?.()
    if (facet?.facets?.length) injectDisplayCountsForFacets(facet?.facets)
  })
}

const filterEmptyValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([, value]) => value))

const chunkFacets = (facetState) => {
  const chunkedFacets = Object.entries(facetState).reduce((chunked, [key, value]) => {
    let valuesList = []
    if (!Array.isArray(value)) valuesList.push(value)
    else valuesList = value
    valuesList.forEach((val) => {
      chunked.push(`${key}=${val}`)
    })
    return chunked
  }, [])
  return chunkedFacets
}

const cleanedQuery = (query = {}) => {
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (temporarySupportedParams[key]) acc[temporarySupportedParams[key]] = value
    else acc[key] = value
    return acc
  }, {})
}

export const useSearchQuery = () => {
  const { signedIn } = useUser()
  const stringifyOptions = { indices: false }
  const { query, setQuery } = useQueryParams({ stringifyOptions })
  const { setQuery: replaceSetQuery } = useQueryParams({ replaceState: true })

  const oldParams = Object.keys(temporarySupportedParams)
  const hasOldParam = Boolean((Object.keys(query).filter((param) => oldParams.includes(param)) || [])?.length)
  if (hasOldParam) {
    replaceSetQuery(cleanedQuery(query), true)
  }

  const localState = Object.fromEntries(
    Object.entries(query).filter(([key = '']) => {
      return localStateParams.includes(key)
    })
  )

  const paginationState = Object.fromEntries(
    Object.entries(query).filter(([key = '']) => {
      return paginationParams.includes(key)
    })
  )

  const { page, results } = paginationState
  paginationState.page = Number.isInteger(Number(page)) ? Number(page) : 1
  paginationState.page = paginationState.page > -1 ? paginationState.page : 1
  paginationState.results = 12
  if (signedIn) paginationState.results = [12, 24, 48, 60].includes(Number(results)) ? Number(results) : 12

  const facetState = Object.fromEntries(
    Object.entries(query).filter(([key = '']) => {
      const [prefix] = key.split('.')
      return facetPrefixes.includes(`${prefix}.`)
    })
  )

  const searchState = Object.fromEntries(
    Object.entries(query).filter(([key = '']) => {
      const [prefix] = key.split('.')
      return searchPrefixes.includes(key) || searchPrefixes.includes(`${prefix}.`)
    })
  )

  const spreadObjWithoutOverwrittingDuplicates = (obj1 = {}, obj2 = {}) => {
    const newObj = { ...obj1 }
    Object.entries(obj2).forEach(([key, value]) => {
      if (newObj[key] && Array.isArray(newObj[key])) newObj[key] = [...newObj[key], value]
      else if (newObj[key]) newObj[key] = [newObj[key], value]
      else newObj[key] = value
    })
    return newObj
  }

  const updateFacetSelectionWithParamList = useCallback(
    (params) => {
      const newFacets = params.reduce((facets, queryString) => {
        try {
          const qsObj = Object.fromEntries(new URLSearchParams(queryString).entries())
          return spreadObjWithoutOverwrittingDuplicates(facets, qsObj)
        } catch (error) {
          return facets
        }
      }, {})
      setQuery({ ...localState, ...paginationState, ...{ page: 1 }, ...searchState, ...newFacets }, true)
    },
    [localState, searchState, paginationState, setQuery]
  )

  const updateSearchState = useCallback(
    (newSearchState) => {
      const filterEmpty = filterEmptyValues(newSearchState)
      setQuery({ ...localState, ...paginationState, ...{ page: 1 }, ...filterEmpty, ...facetState }, true)
    },
    [localState, facetState, paginationState, setQuery]
  )

  const setTab = useCallback(
    (tab = 'all') => {
      setQuery({ ...localState, tab, page: 1, results: 12, ...searchState }, true)
    },
    [localState, searchState, setQuery]
  )

  const actions = {
    updateFacetSelectionWithParamList,
    updateSearchState,
    setTab,
    setQuery,
  }

  const queries = {
    query,
    localState,
    facetState,
    searchState,
    paginationState,
    chunkedFacets: chunkFacets(facetState),
  }

  return { actions, queries }
}

const getQuickSearchV2 = async ({ type, query = {}, pagination = { page: 1, results: 12 }, saveSearch = false }) => {
  const URL = '/service/search/discovery/orchestration/one-search'
  const { page = 1, results = 12 } = pagination
  const from = (page - 1) * results
  const size = results
  const params = { from, size, searchType: type, ...query, saveSearch }

  if (['tree', 'records'].includes(type)) {
    params['c.race'] = 'on'
    params.includeFacets = true
  }

  if (['records'].includes(type)) {
    params['m.queryRequireDefault'] = 'on'
  }

  // Remove params with an empty string
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, val]) => !(typeof val === 'string' && val.trim().length === 0))
  )

  const stringifiedParams = getQueryString(filteredParams)
  await getAnonSession()
  const sessionInfo = getAnonSession()
  const sessionId = sessionInfo?.session?.id || sessionInfo?.id
  const headers = getOSOHeader()
  headers.Authorization = `Bearer ${sessionId}`
  return axios.get(`${URL}?${stringifiedParams}`, {
    headers,
  })
}

const SUCCESS_STATUS = 'SUCCESS'

export const useRecords = ({ search = {}, facets = {}, pagination = {}, saveSearch = false } = {}) => {
  return useQuery(
    ['hr-search', search, facets, pagination],
    async () => {
      const resp = await getQuickSearchV2({ type: 'records', query: { ...search, ...facets }, pagination, saveSearch })
      if (resp?.data?.recordStatus !== SUCCESS_STATUS) throw new Error('No Historical Records Response Received.')
      injectDisplayCountsForFacets(resp?.data?.facets)

      return {
        count: resp.data.recordsTotal,
        results: resp.data.results?.map((record) => formatRecord(record)),
        facets: resp.data.facets?.[0]?.facets, // remove one-search parent facet, children of it only
      }
    },
    { enabled: Boolean(search?.['q.surname']?.length), retry: false, keepPreviousData: true }
  )
}

export const useTree = ({ search = {}, facets = {}, pagination = {}, saveSearch = false } = {}) => {
  return useQuery(
    ['tree-search', search, facets, pagination],
    async () => {
      const extras = { includeMemoriesArtifacts: true }
      const resp = await getQuickSearchV2({
        type: 'tree',
        query: { ...search, ...facets, ...extras },
        pagination,
        saveSearch,
      })
      if (resp?.data?.treeStatus !== SUCCESS_STATUS) throw new Error('No Tree Response Received.')
      injectDisplayCountsForFacets(resp?.data?.facets)

      return {
        count: resp.data.treesTotal,
        results: resp.data.results?.map((result) => formatTreeResult(result)),
        facets: resp.data.facets?.[0]?.facets,
      }
    },
    { enabled: Boolean(search?.['q.surname']?.length), keepPreviousData: true }
  )
}

export const useMemories = ({ search = {}, facets = {}, pagination = {}, saveSearch } = {}) => {
  return useQuery(
    ['memories-search', search, facets, pagination],
    async () => {
      const resp = await getQuickSearchV2({ type: 'memories', query: { ...search, ...facets }, pagination, saveSearch })
      if (resp?.data?.memoriesStatus !== SUCCESS_STATUS) throw new Error('No Memories Response Received.')
      return { count: resp.data.memoriesTotal, results: resp.data.results, facets: resp.data.facets }
    },
    { enabled: Boolean(search?.['q.surname']?.length) }
  )
}

export const useSurname = (surname, friendSurname) => {
  surname = surname?.toLowerCase?.()
  friendSurname = friendSurname?.toLowerCase?.()
  return useQuery(
    ['surname-search', surname, friendSurname],
    async () => {
      const resp = await getQuickSearchV2({
        type: 'surname',
        query: friendSurname ? { 'q.surname': surname, 'q.friendSurname': friendSurname } : { 'q.surname': surname },
      })
      if (resp?.data?.surnameStatus !== SUCCESS_STATUS) throw new Error('Surname failed')
      const surnameDetails = resp?.data?.results?.[0]
      const countries = Array.isArray(surnameDetails?.countries) ? surnameDetails.countries.slice(0, 3) : []
      return {
        count: resp?.data?.surnamesTotal,
        surname: surnameDetails?.surname,
        countries,
        htmlDefinition: surnameDetails?.htmlDefinition,
        attribution: surnameDetails?.attribution,
        attributionURL: surnameDetails?.attributionURL,
        variants: Array.isArray(surnameDetails?.variants?.VARIANT) ? surnameDetails?.variants?.VARIANT : [],
        friendSurname: surnameDetails?.friendSurname,
      }
    },
    { enabled: Boolean(surname?.length), retry: 3 }
  )
}
