import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@fs/zion-ui'
import { NoRecords } from '../../components'
import SmallNoResults from '../NoResults/SmallNoResults'
import BigNoResults from '../NoResults/BigNoResults'

export default function RecordsError({ small }) {
  const [t] = useTranslation()
  const data = {
    Image: NoRecords,
    heading: small
      ? t('one-search.historical-records.action', 'Historical Records')
      : t('one-search.records-empty.heading', 'Sorry, we didn’t find any historical records.'),
    subheading: small
      ? t(
          'one-search.preview-records-empty.subheading',
          "These documents share details about a person's life. To find historical records, try the advanced search. Include additional details like dates or locations."
        )
      : t(
          'one-search.records-empty.subheading',
          'Give it another try with an advanced search. Include additional details like dates or locations.'
        ),
    testid: 'records-empty',
  }

  return small ? (
    <SmallNoResults {...data}>
      <Button to="/search" external linkName="Records:Error:Search">
        {t('one-search.record-search.action', 'Record Search')}
      </Button>
    </SmallNoResults>
  ) : (
    <BigNoResults {...data}>
      <Button to="/search" external linkName="Records:Error:Search">
        {t('one-search.record-search.action', 'Record Search')}
      </Button>
    </BigNoResults>
  )
}
