import React from 'react'
import { Row, Separator, useContainerWidth, H2 } from '@fs/zion-ui'

export default function PreviewSection({ heading, children, total }) {
  const atWidth = useContainerWidth()

  return (
    <>
      {total === null && children}
      {total > 0 && (
        <>
          <Separator size="xl" />
          <Row alignX="center">
            <div style={{ maxWidth: 900 }}>
              <H2 centered columns={atWidth({ default: 12, xl: 9 })} size="H3">
                {heading}
              </H2>
            </div>
          </Row>
          <Separator size="xl" />
          {children}
        </>
      )}
    </>
  )
}
