import React from 'react'
import { Paragraph, colors, Row, Separator, useContainerWidth, H2, FlowGrid, Card, MarginBox, Bleed } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { baseUrl } from '@fs/zion-config'
import { Link } from '@fs/zion-router'
import { i18n } from '@fs/zion-locale'
import { useUser } from '@fs/zion-user'
import whatIsALastNameCardWebp from '../../assets/WhatIsALastNameCard.webp'
import whatDoesItCostSVG from '../../assets/WhatDoesItCost.svg'
import { NAV_IDS } from './StickyNavSurnameSection'
import { SurnameSectionWrapper } from './SurnameSection'
import AuthProviderButtons from '../../AuthProviderButtons'
// import DebugWidth from '../DebugWidth'

function WhatIsASurnameSectionCard({ testId, imageSrc, heading, description, linkText, linkName, signIn = false, to }) {
  const atWidth = useContainerWidth()

  return (
    <Card data-testid={testId} margins="regular">
      <Bleed top bottom sides>
        <div
          style={{
            maxWidth: 600,
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {/* <DebugWidth /> */}
          <Row alignX="center" wrap gutters="none">
            <MarginBox marginX={atWidth({ default: 'lg', md: 'md', xl: 'xl' })} marginY="xl">
              <div style={{ maxWidth: atWidth({ default: 327, md: 448 }) }}>
                <H2 centered size="H3" id="what-is-last-name">
                  <div style={{ fontSize: 28 }}>{heading}</div>
                </H2>
                <Separator size="md" />
                <Paragraph centered data-testid="what-is-last-name-description" size="sm">
                  {description}
                </Paragraph>
                <Separator size="md" />
                {signIn && <AuthProviderButtons dense linkNamePrefix="WhatIsASurname" />}
                {!signIn && (
                  <Row alignX="center">
                    <Link isGray wayfinding linkName={linkName} emphasis="high" to={to}>
                      {`${linkText} ›`}
                    </Link>
                  </Row>
                )}
              </div>
            </MarginBox>
          </Row>
          <Row alignY="bottom" alignX="center">
            {atWidth({
              default: <img src={imageSrc} alt={heading} height="auto" width="var(--cell-width)" />,
              md: <img alignY="bottom" src={imageSrc} alt={heading} height="auto" width="var(--cell-width)" />,
            })}
          </Row>
        </div>
      </Bleed>
    </Card>
  )
}

function CardGrid({ children }) {
  const atWidth = useContainerWidth()
  return atWidth({
    default: (
      <>
        {children.map((child, i) => {
          return i < children.length ? (
            <React.Fragment key={child.key}>
              {child}
              <Separator size="lg" key={`${child.key}-separator`} />
            </React.Fragment>
          ) : (
            { child }
          )
        })}
      </>
    ),

    lg: (
      <FlowGrid columnCount={2} gutters="sm" guttersY="md">
        {children}
      </FlowGrid>
    ),
  })
}

function WhatIsASurnameLoginPanelCard() {
  const [t] = useTranslation()
  const whatDoesThisCostCardId = 'what-does-this-cost-card'

  return (
    <WhatIsASurnameSectionCard
      key={whatDoesThisCostCardId}
      testId={whatDoesThisCostCardId}
      imageSrc={whatDoesItCostSVG}
      heading={t('surname.what-is-a-surname-section.what-does-this-cost.heading', 'What does this cost?')}
      description={t(
        'surname.what-is-a-surname-section.everything-is-free.description',
        'Everything on FamilySearch is free. Signing up will expand your experience—build a family tree, search for your roots, and capture memories.'
      )}
      signIn
      to={`${baseUrl}/identity/signup/`}
    />
  )
}

function WhatIsASurnameAllAboutMeCard() {
  const [t] = useTranslation()
  const allAboutMeCardId = 'all-about-me-card'
  return (
    <WhatIsASurnameSectionCard
      key={allAboutMeCardId}
      testId={allAboutMeCardId}
      imageSrc={whatDoesItCostSVG}
      heading={t('surname.what-is-a-surname-section.all-about-me.heading', 'Try "All about Me"')}
      description={t(
        'surname.what-is-a-surname-section.all-about-me.description',
        'Explore interesting facts based on your name and the year you were born. You can learn things like your name’s meaning and origin, and what life was like the year you were born.'
      )}
      linkText={t('surname.what-is-a-surname-section.try-it-action.label', 'Try it')}
      linkName="WhatIsASurnameSection: TryIt"
      to={`${baseUrl}/discovery/about/`}
    />
  )
}

export function WhatIsASurnameCardSectionContent() {
  const { t } = useTranslation()
  const user = useUser()
  const showProviderLogin = !user?.userLoading && !user?.signedIn
  const whatIsALastnameCardId = 'what-is-a-lastname-card'

  return (
    <Row alignX="center" wrap>
      <CardGrid>
        <WhatIsASurnameSectionCard
          key={whatIsALastnameCardId}
          testId={whatIsALastnameCardId}
          imageSrc={whatIsALastNameCardWebp}
          heading={t('surname.what-is-a-surname-section.what-is-a-lastname.heading', 'What is a last name?')}
          description={t(
            'surname.what-is-a-surname-section.a-lastname-is.description',
            "A last name, also known as a family name or surname, is typically passed down from one generation to the next. Surnames can provide insights into a person's ancestry, cultural background, or geographic origin."
          )}
          linkText={t('surname.what-is-a-surname-section.learn-more-action.label', 'Learn More')}
          linkName="WhatIsASurnameSection: LearnMore"
          to={`${baseUrl}/${i18n.language ?? 'en'}/surname/cms/what-is-a-last-name`}
        />
        {showProviderLogin ? <WhatIsASurnameLoginPanelCard /> : <WhatIsASurnameAllAboutMeCard />}
      </CardGrid>
    </Row>
  )
}

export default function WhatIsASurnameCardSection() {
  const style = { background: colors.gray02, minWidth: 320 }
  return (
    <SurnameSectionWrapper
      name="what-is-a-lastname"
      navId={NAV_IDS.whatIsASurname}
      layoutBandStyle={style}
      marginX="nano"
      marginY="lg"
      maxWidth="lg"
    >
      <WhatIsASurnameCardSectionContent />
    </SurnameSectionWrapper>
  )
}
