import React from 'react'
import { Switch, Route, NotFound } from '@fs/zion-router'
import ErrorBoundary from '@fs/zion-error-boundary'
import { BrightspotRouting } from '@fs/zion-cms'
import { surnameBase, surnamesBase, surnameDirectory } from './paths'
import SurnameExperience from './components/SurnamePage/SurnameExperience'
import SurnameDirectory from './components/SurnamePage/SurnameDirectory'
import SharedSurnamesExperience from './pages/SharedSurnamesExperience/SharedSurnamesExperience'

function App() {
  return (
    <ErrorBoundary>
      <Switch>
        {/**
         * We need to be able to include the locale for /surname because of its routing in server.js.
         */}
        <Route path={surnameBase} component={SurnameExperience} exact />
        <Route path={`${surnameDirectory}/:name`} component={SurnameDirectory} exact />
        <Route path={surnamesBase} component={SharedSurnamesExperience} />
        <Route path="/surname/cms" render={() => <BrightspotRouting site="surname" />} />

        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  )
}
export default App
