import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SurnameExperienceCore from './SurnameExperienceCore'

const SurnameExperience = ({ children, ...rest }) => {
  const queryClientRef = React.useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 1000 * 60 * 1000,
          cacheTime: 1000 * 60 * 1000,
        },
      },
    })
  )
  return (
    <QueryClientProvider client={queryClientRef.current}>
      <SurnameExperienceCore {...rest} />
    </QueryClientProvider>
  )
}

export default SurnameExperience
