import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph, LayoutBand, Row, Separator, useContainerWidth, H3 } from '@fs/zion-ui'

const BigNoResults = ({ Image, heading, subheading, testid = 'unknown', children }) => {
  const atWidth = useContainerWidth()
  return (
    <LayoutBand data-testid={`no-results-big-${testid}`} style={{ minHeight: '50vh' }}>
      <Separator
        size={atWidth({
          default: 'lg',
          sm: 'xl',
        })}
      />
      <Row alignX="center">
        <Image />
      </Row>
      <Separator size="sm" />
      <H3 centered size="H4">
        {heading}
      </H3>
      <Separator size="nano" />
      {atWidth({
        default: (
          <Paragraph centered size="sm">
            {subheading}
          </Paragraph>
        ),
        sm: (
          <Paragraph centered size="sm">
            {subheading}
          </Paragraph>
        ),
      })}
      <Separator size="sm" />
      <Row alignX="center">{children}</Row>
      <Separator size="sm" />
    </LayoutBand>
  )
}

BigNoResults.propTypes = {
  Image: PropTypes.func,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  testid: PropTypes.string,
}

export default BigNoResults
