import React from 'react'
import { Bleed, Paragraph, Card, Image, Separator, usePageWidth, H3 } from '@fs/zion-ui'
import { Button } from '@fs/zion-ui/dist/cjs/buttons'

const CollectionCard = ({ imageSrc, imageTitle, bodyText, cardLink, cardColor = '#000', linkUrl, dataTestid }) => {
  const isMobile = usePageWidth()({ default: true, xxl: false })

  return (
    <Card backgroundColor={cardColor} to={linkUrl} external data-testid={dataTestid}>
      <Bleed all growY>
        <Image src={imageSrc} alt={imageTitle} height="370px" positionY="top" />
      </Bleed>
      <Separator size={isMobile ? 'lg' : 'md'} />
      <H3 size="H5">{imageTitle}</H3>
      <Separator size="xxs" />
      <Paragraph secondary size="sm">
        {bodyText}
      </Paragraph>
      <Separator size="sm" />
      <Button linkName="CollectionCard: TreeCollection">{cardLink}</Button>
    </Card>
  )
}

export default CollectionCard
