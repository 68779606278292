import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph, useContainerWidth, colors, Row, Button, H3, H4, Separator } from '@fs/zion-ui'
import { useUser } from '@fs/zion-user'
import { Link } from '@fs/zion-router'
import { css } from '@emotion/core'
import { SearchPerson } from '../../components'

const contentPositioning = css`
  display: flex;
  min-height: 72px;
`

const cell0LG = css`
  padding: 12px;
  width: calc(200% / 12);
`
const cell0XXS = css`
  padding: 12px;
  width: calc(300% / 12);
  min-width: 72px;
`

const cell1SM = css`
  padding: 12px;
  width: calc(1000% / 12);
`
const cell1LG = css`
  padding: 12px;
  width: calc(900% / 12);
`

const notBottomTdCss = css`
  border-bottom: 1px solid ${colors.transparent.gray30};
`

function HelpRowPositioner({ notBottom, children }) {
  const atWidth = useContainerWidth()
  return (
    <tr data-testid="hr-table-row-help">
      <td colSpan="4" css={notBottom ? notBottomTdCss : undefined}>
        <div css={atWidth({ default: null, sm: contentPositioning })}>
          <div
            data-testid="help-row-picture"
            css={atWidth({
              default: cell0XXS,
              lg: cell0LG,
            })}
          >
            <SearchPerson />
          </div>
          <div
            data-testid="help-row-data"
            css={atWidth({
              sm: cell1SM,
              lg: cell1LG,
            })}
          >
            {children}
          </div>
        </div>
      </td>
    </tr>
  )
}

export function LoggedOutHelpRow({ notBottom }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()

  return (
    <HelpRowPositioner notBottom={notBottom}>
      <Separator size="nano" />
      {atWidth({
        default: (
          <H4 size="H5" supplementary>
            {t('one-search.discover-more.label', 'Discover even more?')}
          </H4>
        ),
        lg: <H4 size="H5">{t('one-search.discover-more.label', 'Discover even more?')}</H4>,
      })}
      <Separator size="nano" />
      <Paragraph size="sm">
        {t('one-search.login-to-see-more.blurb', 'Create a free account to see the rest of the search results.')}
      </Paragraph>
      <Separator size="xxs" />
      <Row>
        <Button
          emphasis="medium"
          to={`/register/?state=${encodeURIComponent(window.location.href)}`}
          linkName="Create Account"
          external
        >
          {t('one-search.create-free-account.action', 'Create a free account')}
        </Button>
        <Button
          to={`/auth/familysearch/login?returnUrl=${encodeURIComponent(window.location.href)}`}
          linkName="Sign In"
          external
        >
          {t('common-ui:sign-in.action', 'Sign in')}
        </Button>
      </Row>
    </HelpRowPositioner>
  )
}

export function LowResultsHelpRow({ notBottom }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { signedIn } = useUser()

  if (!signedIn) {
    return <LoggedOutHelpRow notBottom={notBottom} />
  }

  return (
    <HelpRowPositioner notBottom={notBottom}>
      {atWidth({
        default: (
          <H4 size="H5" supplementary>
            {t('one-search.low-results.label', 'Not getting many results?')}
          </H4>
        ),
        lg: <H4 size="H5">{t('one-search.low-results.label', 'Not getting many results?')}</H4>,
      })}
      <Paragraph size="sm">
        {t(
          'one-search.low-results.retry-quick-search.label',
          'Try substituting different information in your search or leaving it out.'
        )}{' '}
        <Link to=".">{t('one-search.quick-search.action', 'Quick Search')}</Link>
      </Paragraph>
      <Paragraph size="sm">
        {t(
          'one-search.or-try-advanced-search.text',
          'Or you can try the advanced search to find more results about ancestors.'
        )}{' '}
        <Link to="/search" linkName="Records:Low Results:Search" external>
          {t('one-search.record-search.action', 'Record Search')}
        </Link>
      </Paragraph>
    </HelpRowPositioner>
  )
}

export function HighResultsHelpRow({ notBottom }) {
  const [t] = useTranslation()
  const atWidth = useContainerWidth()
  const { signedIn } = useUser()

  if (!signedIn) {
    return <LoggedOutHelpRow notBottom={notBottom} />
  }

  return (
    <HelpRowPositioner notBottom={notBottom}>
      {atWidth({
        default: (
          <H3 size="H5" supplementary>
            {t('one-search.high-results.label', 'Not finding what you are looking for in the quick search?')}
          </H3>
        ),
        lg: (
          <H3 size="H5">
            {t('one-search.high-results.label', 'Not finding what you are looking for in the quick search?')}
          </H3>
        ),
      })}
      <Paragraph size="sm">
        {t(
          'one-search.high-results.try-advanced-search.label',
          'Try the advanced search to find more historical record results.'
        )}{' '}
        <Link to="/search" linkName="Records:High Results:Search" external>
          {t('one-search.record-search.action', 'Record Search')}
        </Link>
      </Paragraph>
    </HelpRowPositioner>
  )
}
