import React from 'react'
import { colors, LayoutBand, MarginBox, Row, Separator, Paragraph } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { surnameDirectory } from '../../../../paths'
import { NAV_IDS } from './StickyNavSurnameSection'
import { formatNameParam } from '../../../../ResultsPage/utils'

const alphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')

function getNarrowScreenAlignmentMinWidth(surnamePrefixes) {
  const prefixLength = surnamePrefixes?.[0]?.length || 0
  const extraLength = prefixLength ? (prefixLength - 1) * 10 : 0
  return 16 + extraLength
}

export function BrowseSurnameLinks({ surnamePrefixes, alignX = 'center' }) {
  const minWidth = getNarrowScreenAlignmentMinWidth(surnamePrefixes)
  return (
    <MarginBox marginX="md" marginY="sm">
      <Row alignX={alignX} guttersY="nano" wrap>
        {surnamePrefixes?.map?.((surnamePrefix) => {
          const to = `${surnameDirectory}/${formatNameParam(surnamePrefix)}`
          return (
            <div key={surnamePrefix} style={{ minWidth }}>
              <Link
                to={to}
                linkName="BrowseSurnamesSection: BrowseLastNameLetter"
                style={{ textDecoration: 'none' }}
                wayfinding
              >
                {surnamePrefix}
              </Link>
            </div>
          )
        })}
      </Row>
    </MarginBox>
  )
}

export default function BrowseSurnamesSection() {
  const { t } = useTranslation()
  return (
    <LayoutBand
      alignY="middle"
      style={{ background: colors.gray00, minWidth: 320 }}
      data-testid={`${NAV_IDS.browseSurnames}-section`}
      id={NAV_IDS.browseSurnames}
    >
      <MarginBox as="section" marginX="lg" marginY="lg">
        <Separator size="sm" />
        <Row alignX="center" wrap gutters="nano">
          <Paragraph>
            {t('surname.browse-surnames-section.browse-surnames.header', 'Browse last names alphabetically:')}
          </Paragraph>
        </Row>
        <BrowseSurnameLinks surnamePrefixes={alphabet} />
        <Separator size="sm" />
      </MarginBox>
    </LayoutBand>
  )
}
